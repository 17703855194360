@media screen and (min-device-width: 1440px) {
  .main-product .product-page.larger-product img {
    height: 524px !important;
  }
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1440px) {
  .second-part {
    position: relative;
    top: 10px;
  }

  .text-custom h3 {
    font-size: 28px !important;
  }

  .second-part.wishlist_ul {
    top: 0;
    width: 100%;
  }

  .text-custom img {
    width: 50% !important;
  }

  .owl-carousel.related-product-list .owl-nav {
    top: 19% !important;
  }

  .top-brands-owl .owl-next::after {
    right: -16px;
  }

  .carousel-caption {
    width: 50% !important;
  }

  .text-custom {
    margin-top: 0 !important;
  }

  .tab-left-content {
    margin-right: 0;
    left: -40px;
  }

  .owl-carousel.related-product-list .owl-nav {
    top: 0;
    right: 44px;
  }
}

@media screen and (min-device-width: 1400px) and (max-device-width: 1440px) {
  .product-page.larger-product img {
    width: 100%;
    height: 521px !important;
  }

  .tab-left-content {
    margin-right: 0;
    left: -117px;
  }

  .contact3rd {
    position: absolute;
    bottom: 12%;
    width: 100%;
    left: 3%;
  }

  .contact1st {
    position: absolute;
    bottom: 10%;
    width: 100%;
    left: 3px;
  }

  .contact2nd {
    position: absolute;
    bottom: 10%;
    width: 100%;
    left: 2%;
  }
}

@media screen and (max-device-width: 1366px) {
  .col-lg-10.padding-right-blog {
    padding-right: 120px !important;
  }

  .contact-info li a {
    font-size: 12px !important;
  }

  .nav-space .nav-item.nav-link {
    font-size: 12px !important;
    padding: 19px !important;
  }

  nav.navbar-nav.mx-auto.cus-a.py-0.nav-space.nav-d-lg.navbar.navbar-expand-lg.navbar-light {
    margin-top: 0px !important;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
  .product-page.larger-product img {
    height: 522px !important;
  }

  .caro-img-outer img {
    object-fit: cover;
    height: 560px;
  }

  .caro-img-outer {
    width: 100%;
    height: 570px;
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1050px) {
  .caro-img-outer {
    width: 100%;
    height: 430px;
  }

  .caro-img-outer img {
    object-fit: cover;
    height: 420px;
  }

  .btn.btn-contact {
    width: 100%;
    max-width: 180px;
    border-radius: 30px;
    height: 40px;
    font-size: 17px;
    font-weight: 600;
    box-shadow: 0px 0px 3px #ededed;
  }

  .contact2nd {
    position: absolute;
    bottom: 11%;
    width: 100%;
    left: 2%;
  }

  .contact2nd .btn.btn-contact {
    background: #d1d3ea;
    color: #375b63;
    left: 5%;
    position: relative;
  }

  .contact1st {
    position: absolute;
    bottom: 11%;
    width: 100%;
    left: -37px;
  }

  .contact3rd {
    position: absolute;
    bottom: 12%;
    width: 100%;
    left: 6%;
  }

  .main-Search .input-group.input-width {
    min-width: 460px;
  }

  .product-page.larger-product {
    height: 422px;
  }

  .navbar-nav.mx-auto.cus-a.navbar.navbar-expand-lg.navbar-light {
    padding: 0 !important;
  }

  .mx-auto.navbar-nav .nav-link {
    font-size: 14px !important;
    padding: 0 19px !important;
  }

  .service h1 {
    font-size: 17px;
  }

  .details h3 {
    font-size: 15px;
  }

  .tab-left-content {
    width: 100%;
    left: 0;
    margin-bottom: 20px;
  }

  .tab-three-content {
    width: 950px;
  }

  .carousel-caption {
    left: 3% !important;
    text-align: center;
    width: 50%;
    top: 12%;
  }

  .header-part {
    padding: 25px 20px;
  }

  .nav-link {
    font-size: 14px;
    padding: 0 12px !important;
  }

  .nav-item.active {
    padding-bottom: 6px;
  }

  .product-page.larger-product img {
    height: 440px !important;
  }

  .btn-hover {
    padding: 10px 20px;
  }

  .navbar-brand {
    width: 58px;
    margin-left: 38px;
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 1100px) {
  .store {
    padding: 15px;
  }
}

@media screen and (min-device-width: 800px) and (max-device-width: 850px) {
  .text-custom {
    margin-top: 0px;
  }

  .text-custom h3 {
    font-size: 38px;
  }

  .owl-carousel.related-product-list .owl-nav {
    top: 27% !important;
  }

  .product-conetnt {
    width: auto;
  }

  .product-page.larger-product {
    height: 313px;
  }

  .product-page.larger-product img {
    height: 329px;
  }

  .navbar-light .navbar-toggler-icon {
    top: -44px;
  }

  .text-custom h4 {
    padding: 10px 0;
  }

  .carousel-caption {
    left: 2% !important;
    top: 5%;
    width: 50%;
  }

  .tab-flied {
    padding-left: 0px;
    margin: 15px 15px 0px 15px;
  }

  .tab-left-content {
    width: 100%;
    left: 0;
    margin-bottom: 20px;
  }

  .tab-three-content {
    width: 715px;
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 768px) {
  .top-brands-owl.icon-new .owl-nav {
    top: 63px !important;
  }

  .tab-flied .tab-content {
    margin-top: 18px !important;
  }

  .phone-fix-card {
    position: absolute;
    right: 45%;
    top: 38%;
  }

  .sticky-top-custom .btn-cus {
    position: absolute;
    text-align: center;
    right: 80px;
    top: 35px;
  }

  .phone-fix-card {
    display: block;
  }

  .phone-fix-card {
    display: block !important;
  }

  .text-custom h3 {
    font-size: 33px !important;
  }

  .text-custom {
    margin-top: 70px;
  }

  .text-custom img {
    width: 140px !important;
  }

  .carousel-caption {
    left: 4% !important;
  }

  .first-part {
    width: 78%;
  }

  a.text-uppercase.side-admin.nav-link {
    display: block;
  }

  .navbar-light .navbar-toggler {
    z-index: 9999;
  }

  .side-admin.nav-link {
    border: 0;
    color: #fff !important;
    position: relative;
    left: 20px;
    right: 0;
    top: -40px;
    font-weight: bold !important;
  }

  .popup-img.text-left {
    text-align: center !important;
  }

  .input-group.otp-input {
    justify-content: center;
  }

  .service-aside {
    width: 310px;
  }

  .add-product-list .add-product {
    width: 327px;
  }

  .pathology h2 {
    font-size: 20px !important;
  }

  .pl-4.pd-remove {
    padding-left: 0px !important;
  }

  .details h3 {
    max-width: 309px;
  }

  .product-page .product-conetnt {
    width: initial;
  }

  .product-conetnt h2 {
    font-size: 22px;
  }

  .popup-img {
    padding: 6px 30px;
  }

  .popup-img h1 {
    font-size: 23px;
  }

  .popup-img .mt-5 {
    margin-top: 15px !important;
  }

  .nav-item.web-hide.nav-link {
    display: block !important;
  }

  .wishlist.sec-part h3 {
    margin-bottom: 25px;
  }

  .wishlist.sec-part button {
    padding: 11px 50px;
  }

  .side-heading {
    margin-bottom: 50px;
  }

  .pd-remove .main-add {
    width: 260px;
  }

  .popup-img img {
    width: 200px;
  }

  .top-product.height-product {
    height: initial;
  }

  .popup-img {
    padding: 20px 40px;
  }

  .modal-dialog.popup-main {
    max-width: 700px !important;
  }

  .text-custom {
    margin-top: 0px;
    width: 90% !important;
  }

  .carousel-caption {
    width: 100% !important;
    top: 0px !important;
  }

  .products-owl .owl-nav {
    top: 150px;
  }

  .product-page.larger-product {
    height: 312px;
  }

  .navbar-light .navbar-toggler-icon {
    left: 0px;
  }

  .top-brands-owl .owl-nav {
    top: 70px;
  }

  .main-service {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .service.text-center {
    margin-bottom: 30px;
  }

  .d-flex.justify-content-between.align-items-center.view-details {
    position: relative;
    padding: 0px;
  }

  .tab-left-content {
    width: 100%;
    left: 0;
    margin-bottom: 20px;
  }

  .tab-three-content {
    width: 715px;
  }

  .store {
    margin-bottom: 20px;
  }

  .p-about {
    line-height: 26px;
  }

  .product-conetnt h2 {
    font-weight: 700;
    font-size: 20px;
  }

  .product-page.larger-product img {
    width: 100%;
    height: 332px;
  }

  .wishlist button {
    padding: 0 20px;
  }
}

@media screen and (min-device-width: 250px) and (max-device-width: 992px) {
  .top-brands-owl .owl-prev::before {
    height: 36px !important;
  }

  .phone-change {
    display: none !important;
  }

  .popular-tests-tab .nav-link {
    font-size: 11px !important;
    padding: 8px 8px !important;
  }

  .popular-tests-tab .nav-link img {
    height: 21px !important;
  }

  .popular-tests-tab .nav-link {
    margin-bottom: 11px !important;
    width: 160px !important;
  }

  .min-height {
    height: 200px !important;
  }

  .nav-space .nav-item.nav-link {
    padding-bottom: inherit;
  }

  .hide-web.nav-link {
    display: block !important;
  }

  .main-Search {
    top: 10px;
    position: relative;
  }

  .wish-cart-ul {
    width: 100%;
  }

  .navbar-light .navbar-toggler-icon {
    position: absolute;
    top: -115px;
    border: 0;
    right: 2px;
    filter: brightness(0) invert(1);
    width: 20px;
    height: 20px;
  }

  .phone-change {
    position: initial !important;
  }

  .input-group.input-width {
    min-width: initial !important;
  }

  .menu-logo {
    height: 50px !important;
  }

  .input-group-text.icon-bg {
    padding: 0px 13px !important;
    font-size: 18px !important;
    height: 42px !important;
  }

  .input-group.input-width input {
    height: 40px !important;
  }

  .top-text {
    display: none !important;
  }

  .navbar.navbar-expand-lg.bottom-pd {
    padding-bottom: 0;
  }

  .wapper-product.Featured-Products img {
    width: 100%;
  }

  .service.text-center {
    margin-bottom: 40px;
    max-width: 250px;
  }

  .service h1 {
    margin-top: 20px !important;
  }

  .btn-cus.mt-5.logout-show {
    display: contents;
  }

  .logout.text-center {
    padding: 80px 10px;
  }

  .logout h2 {
    margin-bottom: 30px;
  }

  .main-edit {
    width: 100% !important;
    margin-bottom: 25px;
  }

  .filter {
    z-index: 9;
  }

  .navbar-toggler {
    z-index: 9999999;
  }

  .popup-img.update-pd {
    padding: 0 20px;
  }

  .product-conetnt.big-pic {
    left: 7px !important;
    bottom: 10px;
  }

  .navbar-light .navbar-toggler {
    border: 0;
  }

  .mx-auto.navbar-nav .nav-item.active.nav-link {
    padding-bottom: 0 !important;
  }

  .navbar-toggler.add-icon-white .navbar-toggler-icon {
    filter: brightness(0) invert(1) !important;
  }

  .carousel-caption {
    text-align: left !important;
  }

  .navbar-toggler-icon {
    object-fit: contain;
    height: 20px;
    background-repeat: no-repeat;
    position: relative;
  }

  button.navbar-toggler.collapsed span.navbar-toggler-icon {
    background-image: url("/assets/img/menu-blue.png") !important;
    background-size: 25px;
    margin-top: 3px;
  }

  button.navbar-toggler span.navbar-toggler-icon {
    background-image: url("/assets/img/menu-white.png") !important;
    background-size: 25px;
    margin-top: 3px;
  }

  .owl-carousel.related-product-list.owl-loaded.owl-drag .wapper-product {
    padding-bottom: 40px;
    width: 95%;
  }

  .owl-carousel.related-product-list .owl-nav {
    top: 27% !important;
  }

  .product-main-div {
    padding: 50px 0;
  }

  .service-aside {
    margin-bottom: 30px;
  }

  .main-content-shop {
    margin-bottom: 30px;
  }

  .col-lg-2.hide-col {
    display: none;
  }

  .footer-link {
    margin-bottom: 30px;
  }

  .btn-cus {
    position: absolute;
    text-align: center;
    right: 80px;
    top: 25px;
  }

  .btn-hover {
    padding: 9px 30px;
  }

  .navbar .navbar-collapse {
    background: #182d48;
    position: fixed;
    width: 85%;
    left: -170%;
    margin-left: 0px;
    height: 100vh;
    top: 0px;
    padding-top: 75px;
    z-index: 999;
  }

  .navbar-collapse.collapse.show .nav-link {
    color: #fff !important;
  }

  .nav-item.nav-link.active {
    padding-bottom: 0px !important;
    display: block !important;
  }

  .navbar .show {
    left: 0%;
    transition: all ease 0.3s;
  }

  .navbar-toggler {
    color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 0);
    position: absolute;
    right: 0px;
  }

  .navbar-toggler {
    z-index: 999;
  }

  .navbar-nav {
    padding-left: 90px;
    margin-top: 0px !important;
    width: 100%;
    float: left;
  }

  .nav-link {
    color: #fff;
    line-height: 31px;
  }

  .nav-item.active {
    border-bottom: 0px solid #182d48;
    padding-bottom: 0px;
  }

  .text-custom h4 {
    padding: 5px 0px;
  }

  .media {
    padding-left: 15px;
  }

  .text-custom p {
    margin-bottom: 21px;
  }

  .text-custom a {
    padding: 9px 26px;
  }

  .tab-back .nav-link {
    color: #333;
  }

  .main-edit {
    margin-bottom: 30px;
  }

  .privacy-content h2 {
    font-size: 30px;
  }

  .nav-btn {
    font-weight: 600 !important;
    color: #fff !important;
  }

  .nav-btn:focus {
    color: #fff !important;
  }

  #dropdown-split-basic {
    padding-right: 0.5625rem;
    padding-left: 0px;
    margin: -2px 0 0 -7px;
    display: flex;
    position: relative;
    z-index: 9999;
  }

  .second-nav-wrp .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    margin: 15px !important;
    margin-bottom: 20px !important;
    width: 250px;
    margin-top: 16px !important;
  }

  .d-flex.dropdown {
    display: initial !important;
    width: 100%;
    float: left;
  }

  .dropdown a.nav-btn.btn {
    width: initial;
    text-align: left;
    position: absolute;
    float: left;
  }

  .outside_click {
    width: 80% !important;
    position: relative !important;
    z-index: 99999;
    float: right !important;
  }

  #dropdown-split-basic {
    color: #fff !important;
    /* margin-top: 19px; */
    float: right;
    width: 30px;
    height: 36px;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin: 21px;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .dropdown_outside {
    position: relative;
    background-color: transparent;
    left: 0px;
    top: 0px;
    box-shadow: none;
    border-radius: 5px;
    z-index: 999;
    width: 125%;
    float: right;
    padding: 0px;
  }

  .dropdown_outside a {
    color: #fff !important;
  }

  .navbar .navbar-collapse {
    width: 83%;
  }

  .dropdown a.nav-btn.btn {
    width: 100%;
    text-align: left;
  }

  .second-nav-wrp .dropdown-item {
    color: #fff !important;
  }
}

@media screen and (max-device-width: 768px) {
  ul.pl-0.mb-0.list-inline.list-block.d-flex.wish-cart-ul.show-hide-nav {
    display: inline-flex !important;
    top: 11px;
    position: absolute !important;
    right: 31px !important;
    width: 250px !important;
    justify-content: flex-end !important;
    text-align: right !important;
  }
  .nav-menu {
    display: flex;
    justify-content: space-between !important;
  }
  .col-lg-10.padding-right-blog {
    padding-right: 10px !important;
  }

  .col-lg-10.padding-right-blog h2,
  .col-lg-10.padding-right-blog h3 {
    word-break: break-word;
  }
  .blog-thumb-box h4 {
    width: 328px;
    font-size: 16px;
  }
  .top-margin {
    margin-top: 100px !important;
  }
  .nav-lg-view {
    display: none !important;
  }
  ul.list-inline.d-flex.cart-icon.pl-3 {
    display: none !important;
  }
  .contact3rd {
    position: absolute;
    bottom: 5%;
    width: 100%;
    left: 6%;
  }

  .div#v-pills-tabContent {
    margin-top: 20px !important;
  }

  .nav-item.dropdown {
    width: 100% !important;
  }

  .second-nav-wrp .nav-link::after {
    top: -8px !important;
  }

  .service-bg .animated {
    text-align: -webkit-center !important;
    text-align: center;
  }

  .privacy-page h1 {
    top: 113px !important;
  }

  .privacy-min-height {
    height: 100% !important;
  }

  .dropdown a {
    color: #fff !important;
  }

  .dropdown.btn-group {
    width: 100%;
    display: initial;
    position: relative;
    padding: 8px;
  }

  .dropdown.btn-group a.btn {
    position: absolute;
    z-index: 3;
  }

  .dropdown.btn-group a {
    display: flex;
    width: 100%;
  }

  .navbar-nav {
    align-items: baseline;
  }

  .detail-right {
    display: block;
    text-align: left !important;
    margin-top: 10px;
  }

  .price-test {
    white-space: nowrap !important;
  }

  .dropdown-menu {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .contact1st {
    position: absolute;
    bottom: 8%;
    width: 100%;
    left: -86px;
  }

  .contact1st .btn.btn-contact {
    background: #fdfeff;
    right: -80%;
    position: relative;
    top: 15px;
    color: #07241c;
    font-size: 17px;
    height: 40px;
  }

  .contact2nd {
    position: absolute;
    bottom: 4%;
    width: 100%;
    left: 6%;
  }

  .btn.btn-contact {
    width: 100%;
    max-width: 184px;
    border-radius: 30px;
    height: 43px;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0px 0px 3px #ededed;
  }

  .react-datepicker-popper {
    transform: translate3d(25px, 115px, 0px) !important;
  }

  .btn-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Category-section {
    min-height: 390px !important;
  }

  .tab-flied .tab-content {
    margin-top: 18px !important;
  }

  section.sec-mar {
    margin-top: 80px;
  }

  .down-section {
    height: 98px !important;
  }

  .left-side-content-tab {
    margin-top: 150px !important;
  }

  .tab-content {
    margin-top: 118px !important;
  }

  section.path-banner {
    margin-top: 140px;
  }

  .rate {
    max-width: 218px !important;
  }

  .home-top-banner {
    margin-top: 135px !important;
  }

  .product-conetnt h2 {
    font-size: 14px;
  }

  .banner-outer-top {
    margin-top: 85px !important;
  }

  .top-text h6 {
    margin-top: 5px;
  }

  .second-nav-wrp .dropdown-menu {
    top: 0px !important;
  }

  .header-part {
    padding: 20px 0px 8px !important;
  }

  .navbar-light .navbar-toggler {
    z-index: 9999;
    top: 10px;
  }

  .header-part.header_align {
    justify-content: space-between !important;
    width: 100% !important;
  }

  .top-text {
    min-height: 45px;
  }

  .path-stat-wrp h5 {
    font-size: 15px !important;
  }

  .view img {
    width: 100%;
    height: auto !important;
    object-fit: cover;
  }

  .view {
    max-height: initial !important;
    width: 100%;
  }

  .path-banner {
    padding: 50px 11px !important;
  }

  ul.phone-nav.pl-0 {
    display: none;
    margin-bottom: 0px !important;
  }

  ul.phone-nav.pl-0 li {
    display: inline-block;
    padding: 0 3px;
    width: fit-content;
    margin: 0;
  }

  ul.phone-nav.pl-0 li a {
    padding: 0px 30px !important;
  }

  .caro-img-outer {
    width: 100%;
    height: auto !important;
  }

  .caro-img-outer img {
    object-fit: cover;
    height: auto !important;
  }

  .view-details a:nth-child(1) {
    font-size: 12px !important;
    height: 50px;
    text-align: center;
    width: 100%;
  }

  h5.mb-0.price-test {
    font-size: 15px;
  }

  .service-aside-int-box {
    min-height: 110px !important;
  }

  .service-aside {
    margin-bottom: 0px !important;
  }

  .vendor-part a {
    font-size: 13px !important;
  }

  .modalCart a {
    max-width: 135px !important;
    width: 100%;
  }

  .vendor-part {
    padding: 30px !important;
  }

  .service-aside h3 {
    font-size: 16px;
  }

  .service-aside p {
    font-size: 14px !important;
  }

  .path-banner {
    background-position: 85% 100% !important;
  }

  .path-banner {
    padding: 35px 11px !important;
  }

  .path-search-box {
    max-width: 100% !important;
  }

  .popular-test {
    padding: 50px 0px 10px 0px !important;
  }

  .popular-test-owl {
    padding-bottom: 0px;
  }

  /* .input-group.input-width {
      min-width: initial !important;
  } */
  .header-part {
    padding: 15px 20px 0px;
  }

  .text-custom p {
    display: none;
  }

  .text-custom h3 {
    margin-bottom: 0px;
  }

  .margin-top {
    margin-top: 0 !important;
  }

  .tab-flied {
    margin-top: 40px;
  }

  .tab-flied {
    padding-left: 0px !important;
    margin: 30px 30px 0px 30px;
  }

  .tab-back {
    min-height: auto;
  }

  .pl-4.side-pd {
    padding-left: 10px !important;
  }

  .tab-three-content .price-details .total-list {
    display: block;
  }

  .setup-content .edit-part {
    height: auto !important;
  }

  .navbar-light .navbar-toggler-icon {
    z-index: 999;
  }

  .contact-info li a {
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 400;
  }

  .second-part a {
    font-size: 11px !important;
  }

  .navbar-nav .nav-link {
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .wapper-product.Featured-Products {
    margin-bottom: 30px;
  }

  .wapper-product.Featured-Products .rate {
    margin-top: 0;
  }

  .my-order-flex .producrt-list {
    display: block;
  }

  .text-custom {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .second-nav-wrp .dropdown-item {
    color: #fff !important;
  }

  .navbar-brand {
    width: 58px;
    margin-left: 38px;
  }
}

@media screen and (min-device-width: 767px) and (max-device-width: 1025px) {
  .wishlist.sec-part {
    text-align: right;
    margin-top: 37px;
    margin-right: 40px;
  }
}

@media screen and (max-device-width: 767px) {
  .left-side-content-tab {
    padding-right: 0px !important;
  }

  nav.navbar-nav.mx-auto.cus-a.py-0.nav-space.nav-d-lg.navbar.navbar-expand-lg.navbar-light {
    margin-top: 0px !important;
  }

  .item .plus {
    background: #66cc8d;
    color: #fff;
    width: 24px;
    bottom: 76px;
    right: 27px;
    z-index: 3;
    float: right;
    height: 23px;
    text-align: center;
    line-height: 25px;
    margin-right: 20px;
    position: inherit !important;
  }

  .align {
    position: absolute !important;
    top: 10px !important;
    right: 30px !important;
  }

  .price-test {
    white-space: nowrap !important;
  }

  .dropdown-menu {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .save-ansave-udated-btn {
    width: 100% !important;
  }

  .shop-concern-upt {
    padding-top: 0px !important;
  }

  .tab-product-price .tab-product-box {
    margin: 0 auto;
  }

  .add-product-list.custom-add {
    margin-bottom: 0;
  }

  .shop-concern-upt {
    padding-top: 0px !important;
  }

  .wapper-product .product.related-list img {
    height: 250px;
  }

  .privacy-page.min-height img {
    object-position: 62% 15%;
  }

  .privacy-page.min-height img {
    height: 50vh !important;
    object-fit: cover;
  }

  .owl-carousel.health-owl .owl-nav {
    display: block !important;
  }

  .health-owl .owl-prev {
    position: absolute;
    left: 20px !important;
    top: 40% !important;
  }

  .health-owl .owl-next {
    position: absolute;
    right: 25px !important;
    top: 40% !important;
  }

  .owl-carousel.people-owl .owl-nav {
    display: block !important;
  }

  .people-owl .owl-nav {
    position: absolute;
    right: 56px !important;
    top: -22px !important;
  }

  .people-about .owl-dots {
    display: none;
  }

  .discount-part {
    margin-right: 0px !important;
  }

  .hm-tp-unique .discount-part {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 0px;
  }

  .discount-part {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }

  .product.height-within-mob .rate {
    display: flex;
  }

  .discount-part {
    margin-right: 20px;
  }

  section.sec-mar {
    margin-top: 80px;
  }

  .lab-test-banner h1 {
    font-size: 23px;
    margin-bottom: 0px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 10px;
    color: #182d48;
    margin-top: 15px;
  }

  .lab-test-banner {
    padding: 75px 25px;
    margin-top: 135px;
    background-position: 70%;
  }

  .dfo-wrapper .col-lg-4 {
    margin-bottom: 30px;
  }

  .whatsapp-banner-content {
    padding: 75px 0px;
    margin-bottom: 50px;
  }

  .wb-btn-wrp {
    justify-content: start;
    margin-top: 15px;
  }

  .wb-banner-content h6 {
    font-size: 16px;
  }

  .nav-btn {
    font-weight: 600 !important;
    color: #fff !important;
  }

  .nav-btn:focus {
    color: #fff !important;
  }
}

@media screen and (min-device-width: 250px) and (max-device-width: 550px) {
  .contact2nd {
    position: absolute;
    bottom: 1%;
    width: 100%;
    left: 7%;
  }
  .top-margin {
    padding-top: 100px !important;
  }
  .remove-move .fa-heart-o {
    left: -88px !important;
    font-size: 14px;
  }

  .modal-message.width-popup-date h2 {
    text-align: center;
    word-break: break-word;
  }

  .enter-pin a {
    margin-left: 10px;
    white-space: nowrap !important;
  }

  .btn.btn-contact {
    width: 100%;
    max-width: 95px;
    border-radius: 30px;
    height: 25px;
    font-size: 10px;
    font-weight: 600;
    box-shadow: 0px 0px 3px #ededed;
  }

  .contact3rd .btn.btn-contact {
    background: transparent;
    color: #fff;
    box-shadow: none;
    font-size: 11px;
    font-weight: 700;
    text-decoration: underline;
  }

  .contact1st .btn.btn-contact {
    background: transparent;
    right: -82%;
    position: relative;
    top: 21px;
    color: #fff;
    box-shadow: none;
    font-weight: 800;
    text-decoration: underline;
    font-size: 12px;
  }

  .contact2nd .btn.btn-contact {
    background: transparent;
    color: #375b63;
    left: -9px;
    position: relative;
    box-shadow: none;
    font-weight: 700;
    top: -5px;
    font-size: 12px;
    text-decoration: underline;
  }

  .contact3rd {
    position: absolute;
    bottom: 4%;
    width: 100%;
    left: 9px;
  }

  .contact1st {
    position: absolute;
    bottom: 7%;
    width: 100%;
    left: -37px;
  }

  .listing-dt-img img {
    height: 300px !important;
    object-fit: contain;
  }

  .category-sort .form-control.css-wmw4vi-ReactDropdownSelect.e1gzf2xs0 {
    height: 0 !important;
    margin-bottom: 4px;
  }

  .custm-loader {
    position: fixed;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
    text-align: center;
    z-index: 9999;
    padding-top: 95%;
    border-radius: 18px !important;
  }

  .product-main-div.change-postion.cust-add-min .pl-4.pd-remove {
    border: 0px;
    background: transparent;
  }

  .shopping-cart {
    margin-top: -50px;
  }

  .tab-back {
    padding: 7px 0 6px 0px;
  }

  .tab-back .nav-link {
    font-size: 13px;
    margin-bottom: 0px;
    padding: 3px 20px !important;
  }

  .category-title h4 {
    font-size: 12px;
  }

  .carousel-caption {
    width: 80% !important;
    top: 0px !important;
  }

  .input-group-text.icon-bg {
    height: 35px !important;
  }

  .home-top-banner {
    margin-top: 140px !important;
  }

  .freq-book-wrapper {
    padding-top: 30px;
  }

  .oh-stat-box,
  .bl-stat-box,
  .om-stat-box {
    padding: 25px;
  }

  .path-banner {
    margin-top: 175px !important;
  }

  .path-banner-content h2 {
    font-size: 20px !important;
    margin-bottom: 9px !important;
    padding-bottom: 10px;
  }

  .text-custom h4 {
    font-size: 13px !important;
    margin: 5px 0px !important;
    line-height: 20px !important;
  }

  .text-custom {
    height: 197px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0px !important;
  }

  .caro-img-outer {
    width: 100%;
    height: 200px !important;
  }

  ul.phone-nav.pl-0 li a {
    padding: 0px 5px !important;
    font-size: 12px !important;
    color: #000;
  }

  .text-custom.new-get.signal-text {
    margin-left: -19px;
  }

  .banner-outer-top .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: 260px !important;
    object-fit: contain !important;
  }

  .popular-tests-tab .nav-item {
    width: 50% !important;
  }

  .popular-tests-tab .nav-link {
    font-size: 10px !important;
    padding: 8px 6px !important;
    margin-bottom: 10px;
  }

  .slider-outer .owl-dots {
    margin-top: 0px;
    bottom: 0px;
  }

  .top-text {
    min-height: 85px;
  }

  .popular-tests-tab .nav-link img {
    height: 25px;
  }

  .rate.d-flex.justify-content-between.mt-0 {
    align-items: center;
  }

  p.discount-part {
    padding-top: 0px;
  }

  .pagination-products li.active {
    color: #fff;
  }

  button.navbar-toggler.collapsed span.navbar-toggler-icon {
    display: none;
  }

  .list-block {
    display: flex;
    justify-content: end;
  }

  .phone-nav {
    display: block !important;
    margin-bottom: 8px !important;
  }

  .phone-nav li {
    list-style: none;
    display: inline-block;
  }

  .phone-nav li a {
    padding-left: 0 !important;
    padding-right: 22px !important;
    font-size: 15px;
    text-transform: capitalize;
  }

  /* .second-part.new-show a {
    color: #fff !important;
  }

  .second-part.new-show a {
    filter: brightness(0) invert(1);
  } */
  .second-part.new-show {
    display: flex !important;
    width: 70%;
  }

  .second-part {
    display: none !important;
  }

  .shop-content {
    min-height: initial;
  }

  .top-brands-owl.icon-new .owl-nav {
    top: 85px !important;
  }

  .navbar-light .navbar-toggler-icon {
    top: -141px !important;
  }

  .phone-change img {
    filter: brightness(0) invert(1);
    width: 13px;
    vertical-align: initial;
  }

  .header-part {
    padding-left: 0 !important;
  }

  .input-group.input-width input {
    font-size: 10px !important;
    height: 35px;
  }

  .main-Search {
    width: 200px !important;
    position: absolute;
    rotate: 0;
    right: 30px;
    top: 27px;
  }

  .top-text {
    padding: 10px 10px;
  }

  .top-text h6 {
    font-size: 11px;
  }

  .phone-change a {
    font-size: 11px;
  }

  .new-meida-part {
    padding-top: 10px;
  }

  .new-price.red-price {
    position: relative;
    left: 0px;
  }

  .apppy-btn {
    display: block !important;
    text-align: center;
    margin: 0 auto;
    border: 0;
    background: #182d48;
    color: #fff;
    padding: 10px 30px;
    font-size: 15px;
  }

  .apply-bg {
    background: #f8f9fb;
    width: 100%;
    float: left;
    position: absolute;
    left: 0;
    z-index: 999999;
    right: 0;
    bottom: 16px;
    padding-top: 10px;
  }

  .fix-page {
    position: absolute;
    right: 40px;
  }

  .overflow-hide {
    margin-top: 0;
  }

  .health h2 {
    font-size: 24px;
    margin: 25px 0px;
  }

  .height-within-mob img {
    width: 100%;
    height: 220px;
    object-fit: contain;
  }

  .my-order-flex .producrt-list {
    width: 100% !important;
  }

  body {
    outline: 0 !important;
  }

  body .dd-show {
    display: block !important;
  }

  body .dd-none {
    display: none !important;
  }

  .col-md-12.left-pd {
    padding-left: 0;
  }

  .details h3 {
    width: auto;
  }

  .listing-dt-img img {
    height: auto;
    object-fit: contain;
  }

  .plus {
    width: 20px;
    height: 20px;
    line-height: 19px;
    position: relative;
    left: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-caption img {
    width: 150px !important;
    height: 60px !important;
    object-fit: contain !important;
  }

  .aisde-left {
    text-align: center;
  }

  .mob-discount {
    display: flex;
    justify-content: center;
  }

  .text-uppercase.side-admin.nav-link {
    padding-left: 0 !important;
  }

  .modal-message.width-popup-date {
    width: 360px;
  }

  .first-part {
    width: 100% !important;
  }

  .copy-right-text {
    position: inherit;
    font-size: 12px;
  }

  .left-side-content-tab {
    position: fixed !important;
    top: 0 !important;
    /* display: none !important; */
    z-index: 99999999;
    display: none;
  }

  .filter.fix-filter {
    width: 100%;
    left: -15px;
    right: 0;
    top: -75px;
  }

  .text-uppercase.side-admin.nav-link:hover {
    color: #fff !important;
  }

  .pagination-products {
    justify-content: center !important;
    position: inherit;
  }

  .close-btn-new {
    display: block !important;
  }

  .close.close-btn {
    right: -8px !important;
    top: -21px !important;
  }

  .btn-right {
    float: none !important;
    text-align: center;
  }

  .custom-star {
    text-align: center;
  }

  .custom-star span {
    text-align: center;
    padding: 5px 0;
  }

  .top-brands {
    padding: 30px 0px !important;
  }

  .phone-center-text {
    text-align: left;
    margin-bottom: 8px;
  }

  .sticky-top-custom {
    box-shadow: initial !important;
  }

  .react-datepicker__input-container {
    text-align: left;
  }

  .react-datepicker__input-container input,
  .react-datepicker__input-container input::placeholder {
    padding: 10px 9px !important;
  }

  .min-height img {
    height: 170px;
    object-fit: cover;
  }

  a.text-uppercase.side-admin.nav-link {
    display: block;
  }

  .side-admin.nav-link {
    border: 0;
    color: #fff !important;
    position: relative;
    left: 20px;
    right: 0;
    top: -20px;
    font-weight: bold !important;
  }

  .down-section {
    padding: 10px 5px !important;
  }

  .top-product {
    padding: 40px 0px !important;
  }

  .rate p {
    font-size: 12px !important;
  }

  .new-price p {
    font-size: 12px !important;
    position: relative;
    left: 0px !important;
    top: 0px !important;
  }

  .top-brands-owl.new-owl-client .owl-nav {
    top: 78px !important;
  }

  .soical-part ul {
    margin-bottom: 0;
  }

  .listing-dt-img.picutres-tab.text-center {
    justify-content: center;
    display: flex;
  }

  .input-group.otp-input {
    justify-content: center !important;
  }

  .product.related-list {
    min-height: inherit !important;
  }

  .tab-product-box.active {
    width: 48%;
    margin-right: 0px;
  }

  .brand-name.Reviews-c {
    display: block !important;
    text-align: center;
  }

  .top-brands-owl img {
    width: auto;
  }

  .people-owl .owl-nav {
    top: -250px !important;
  }

  .product-conetnt h2 {
    font-size: 20px !important;
  }

  .product-conetnt {
    left: 9px;
  }

  .our-product-owl .owl-nav {
    top: -80px;
  }

  .top-brands-owl .owl-nav {
    top: 70px !important;
  }

  .top-brands-owl .owl-next::after {
    right: -34px !important;
  }

  .top-brands-owl .owl-prev::before {
    left: -40px !important;
  }

  .products-owl .owl-prev::before {
    left: -40px !important;
  }

  .products-owl .owl-next::after {
    right: -34px !important;
  }

  .privacy-page h1 {
    font-size: 42px !important;
  }

  .product-conetnt {
    width: auto !important;
    left: 9px !important;
  }

  .enter-pin {
    width: 100% !important;
  }

  .soical-part li a {
    width: 100px !important;
  }

  /* .soical-part ul li {
  margin-bottom: 20px;
} */
  .soical-part li a {
    text-align: center;
  }

  .infomation-part {
    padding: 15px !important;
  }

  .products-owl .owl-nav {
    top: 115px;
    width: 100%;
  }

  .date-btn {
    display: flex;
    justify-content: center;
  }

  .date-setting p {
    text-align: left;
  }

  .tab-flied {
    padding-left: 0px !important;
  }

  .product-img-details p {
    margin-top: 20px;
    margin-left: 0px;
  }

  .add-bg {
    margin-top: 20px;
  }

  .filter-head {
    margin-left: 0px !important;
  }

  .pagination-products {
    width: 100%;
    margin: 30px auto;
    justify-content: center;
  }

  .pagination-products > li > a {
    padding: 5px 6px !important;
  }

  .filter {
    position: relative;
    z-index: 9 !important;
    padding-top: 160px;
  }

  div#v-pills-tabContent {
    padding-left: 0px;
  }

  div#v-pills-tabContent {
    overflow-x: hidden;
    margin-top: 0px !important;
    height: initial;
  }

  .background-info .nav-link {
    padding: 0 10px !important;
  }

  .category-title {
    padding: 10px 10px !important;
  }

  .pathology {
    display: block !important;
    text-align: center;
  }

  .text-custom a {
    font-size: 12px !important;
    padding: 5px 18px !important;
  }

  .text-custom h3 {
    font-size: 13px !important;
  }

  .category-title .category-title h4 {
    margin-right: 8px;
    font-size: 17px;
  }

  .dropdown.custom-dropdown .css-wmw4vi-ReactDropdownSelect * {
    font-size: 12px;
  }

  .react-dropdown-select.form-control.css-wmw4vi-ReactDropdownSelect.e1gzf2xs0 {
    position: relative;
    top: 2px;
    font-size: 13px;
  }

  .details h3 {
    max-width: 90% !important;
    font-size: 12px !important;
    width: 120px;
  }

  .view-all-link a {
    padding-top: 30px;
  }

  .rate p {
    margin-bottom: 0px;
  }

  .product-details .product-img-details {
    display: flow-root;
    text-align: center;
  }

  .main-edit {
    width: 100% !important;
  }

  .edit.float-right {
    margin-bottom: 40px;
  }

  .details h3 {
    max-width: initial;
  }

  .our-scrvice-height .view-details a:nth-child(1) {
    text-align: left;
  }

  .pathology h2 {
    font-size: 20px !important;
  }

  .popup-img .mt-5 {
    margin-top: 15px !important;
  }

  .popup-img input {
    height: 36px !important;
  }

  .button-custom {
    line-height: 42px !important;
    height: 40px !important;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .popup-img p {
    font-size: 14px !important;
  }

  .privacy-content h2 {
    margin-top: 50px;
  }

  .main-add {
    width: auto !important;
  }

  .product-list-main {
    display: block !important;
  }

  .button-custom {
    line-height: 44px;
    height: 46px;
  }

  .modal-dialog.popup-main {
    width: 100% !important;
    max-width: 360px;
  }

  .popup-img h1 {
    font-size: 17px;
  }

  .popup-img img {
    width: 220px;
  }

  .popup-img .otp-input input {
    margin: 6px !important;
    width: 35px !important;
  }

  .error-wrapper {
    bottom: -29px !important;
  }

  .popup-img.text-left {
    text-align: center !important;
  }

  .popup-img {
    padding: 3px 25px !important;
  }

  .button-custom {
    margin-top: 16px !important;
  }

  .nav-item.web-hide.nav-link {
    display: block !important;
  }

  .privacy-page h1 {
    font-size: 32px;
  }

  .product-img-details img {
    width: 55px;
  }

  .side-heading {
    margin-bottom: 30px;
  }

  .product-img-details {
    margin-top: 20px;
    background: #f8f9fb;
    padding: 20px;
  }

  .react-datepicker {
    /* display: flex !important; */
    justify-content: space-between;
  }

  .react-datepicker__time-container {
    width: 73px !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    width: 73px;
  }

  .product-page.larger-product {
    height: auto;
  }

  .side-left:first-child {
    margin-right: 28px;
  }

  .sticky-top-custom .navbar-light .navbar-toggler-icon {
    right: 0;
  }

  .nav-item.nav-link.active {
    padding-bottom: 0px !important;
    display: block !important;
  }

  .media {
    padding-left: 8px !important;
  }

  .number {
    display: block !important;
  }

  .products-owl .owl-prev {
    position: absolute;
    left: 30px;
    top: 30%;
  }

  .products-owl .owl-next {
    position: absolute;
    right: 25px;
    top: 30%;
  }

  .top-brands-owl .owl-nav {
    position: initial;
  }

  .top-brands-owl .owl-prev {
    position: absolute;
    left: 30px;
    top: 39%;
  }

  .top-brands-owl .owl-next {
    position: absolute;
    right: 25px;
    top: 39%;
  }

  .health-owl .owl-nav {
    position: initial;
  }

  .health-owl .owl-prev {
    position: absolute;
    left: 37px;
    top: 39%;
  }

  .health-owl .owl-next {
    position: absolute;
    right: 25px;
    top: 39%;
  }

  .people-owl .owl-nav {
    position: sticky;
    right: 50px;
    top: 0;
  }

  .main-service {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .tab-product-box.active {
    width: 160px;
    margin-right: 0;
  }

  .tab-product-box {
    width: 160px;
    margin-right: 0;
  }

  .product-detalis-silder {
    margin-top: 40px;
  }

  .product-img-static {
    padding: 0px;
  }

  .custom-pd {
    padding: 0px;
  }

  .add-cart button {
    margin: 0px 0 20px 0;
    width: 100%;
  }

  .brand-name p {
    text-align: center;
  }

  .tab-product-price {
    text-align: center;
  }

  .tab-product-price .tab-product-box {
    display: inline-block !important;
    margin-bottom: 10px !important;
  }

  .tab-product-price {
    display: inline-block;
  }

  .add-cart {
    text-align: center;
  }

  .soical-part li a {
    width: 100px;
  }

  .enter-pin a {
    margin-left: 10px;
  }

  .enter-pin input {
    margin-right: 0px !important;
    padding-right: 5px;
  }

  .enter-pin {
    width: 100%;
    margin-top: 40px;
  }

  .product-aside {
    height: 100px;
    width: 74px;
  }

  .product-detalis-owl .owl-next span {
    top: 5px;
    left: -5px;
  }

  .filter {
    display: block !important;
  }

  .left-side-content-tab {
    top: 0px !important;
  }

  .pr-media {
    padding-right: 0px;
  }

  .dropdown-toggle::after {
    top: -11px;
    right: -6px;
  }

  .sort-sarech {
    width: 130px;
  }

  .service-aside {
    margin-bottom: 30px !important;
    padding: 15px !important;
  }

  .cororate {
    display: block;
  }

  .stepwizard-step a {
    line-height: 16px !important;
    justify-content: center;
    align-items: center !important;
    justify-content: center !important;
    position: relative;
  }

  .stepwizard-step.custom-step-part {
    left: 20px !important;
  }

  .tab-left-content {
    left: 0;
    width: 100%;
  }

  .tab-three-content {
    width: 300px;
    left: 30px;
    position: relative;
    top: 30px;
  }

  .cart-bg button.ml-5 {
    margin-left: auto !important;
    display: block;
    text-align: center;
    margin-top: 15px !important;
    margin: 0 auto;
    margin-right: auto !important;
  }

  .banner-dynamic {
    margin-top: 20px !important;
  }

  .view {
    max-height: auto !important;
    width: 100%;
    height: auto !important;
  }

  .banner-silder img {
    object-fit: cover;
    height: auto !important;
  }

  .text-custom h3 {
    font-size: 32px;
  }

  .text-custom a {
    font-size: 12px;
  }

  .nav-link.color {
    display: block;
  }

  .nav-link.color {
    color: #182d48;
  }

  .media {
    padding-left: 55px;
  }

  .btn-cus2 {
    display: block;
  }

  .btn-cus {
    display: none;
  }

  .contact-info li a {
    font-size: 14px;
  }

  .header-part {
    padding: 25px 10px;
  }

  .header-part {
    padding-bottom: 0 !important;
  }

  .footer-link ul {
    justify-content: center;
  }

  .footer-link {
    text-align: left !important;
  }

  .barnd h2 {
    font-size: 24px;
  }

  .number {
    display: block;
    padding-left: 55px;
    margin-top: 11px;
  }

  .or {
    font-size: 15px;
    color: #494949;
    letter-spacing: 0.47px;
    margin-top: 12px;
  }

  .about {
    width: 100%;
  }

  .store {
    margin-bottom: 20px;
  }

  .owl-theme .owl-nav {
    margin-top: 46px !important;
  }

  .products-owl.owl-theme .owl-nav {
    margin-top: 36px !important;
  }

  .custom-nav {
    position: absolute;
    top: 68px;
    z-index: 1;
    background: #fff;
    padding: 20px;
    border-bottom: 1px solid #eee;
  }

  .header-part {
    display: flex !important;
    justify-content: space-between;
  }

  .contact-info {
    display: flex;
    justify-content: space-between;
  }

  .text-uppercase.border-right-custom {
    padding-left: 0px;
  }

  .contact-info.top-part {
    padding-bottom: 0px;
    width: 30%;
  }

  .logo-img {
    width: 220px;
  }

  .product-conetnt {
    width: 310px;
  }

  .our-product-owl .owl-nav {
    top: -130px;
  }

  .product-page img {
    height: 247px;
  }

  .margin-top {
    margin-top: 0 !important;
  }

  .tab-flied {
    padding-left: 0px;
    margin: 102px 30px 0px 30px;
  }

  .tab-back {
    height: 80% !important;
  }

  .second-nav-wrp {
    padding-bottom: 0px;
  }

  .add-product-list {
    display: block !important;
    text-align: center;
  }

  .price {
    text-align: center;
    margin-top: 0;
    background: #dfdfdf;
    border: 1px solid #d7d7d7;
    border-top: 0px;
    padding: 5px;
    font-weight: 700;
  }

  .pd-remove {
    padding-left: 0px !important;
  }

  .sp-quantity {
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
  }

  .detail-sec-wpr .sp-quantity {
    justify-content: start;
  }

  .sec-top-mar {
    margin-top: 165px !important;
  }

  .stepwizard-step.custom-step-part a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stepwizard-row {
    display: flex !important;
    justify-content: space-between;
  }

  .stepwizard-row::before {
    width: 90%;
  }

  .all-test-wrp .col-lg-4 {
    margin-bottom: 0;
  }

  .stepwizard-step.custom-step-part a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 10px;
  }

  .new-height.our-scrvice-height {
    border-bottom: unset !important;
    padding: 0px !important;
  }

  .detail-right .btn {
    float: unset !important;
    margin-top: 15px !important;
  }

  .add-product {
    border: 1px solid rgb(202, 200, 200);
    border-radius: 15px 15px 0 0;
  }

  .pl-4.pd-remove {
    padding-left: 0px !important;
    padding: 15px 0px;
    background: #ebebeb;
    border: 1px solid #d7d7d7;
    border-radius: 0px;
  }

  .sp-minus {
    background: #fff;
    border: 1px solid #d7d7d7;
  }

  .remove-move {
    margin-top: 10px;
  }

  .wishlist.sec-part {
    margin-top: 30px !important;
    text-align: left !important;
    margin-right: 0 !important;
  }

  .shop-content {
    padding: 15px;
  }

  .shop-content h2 {
    font-size: 20px;
  }

  .left-side-content-tab .nav-link {
    padding: 0 !important;
    color: #182d48;
  }

  .navbar-toggler {
    z-index: 9999 !important;
  }

  .left-side-content-tab {
    background: #f8f9fb;
    padding: 16px;
    margin-bottom: 30px;
  }

  .heading-tab h3 {
    font-size: 14px;
    font-weight: 700;
    color: #182d48;
    text-align: center;
  }

  .left-side-content-tab {
    height: calc(100vh - 125px) !important;
    overflow-y: scroll;
    top: 0px !important;
    width: 100%;
    z-index: 9999;
  }

  .my-order-flex .producrt-list {
    display: block;
    text-align: center;
  }

  .my-order-flex {
    display: block !important;
    text-align: center;
  }

  .more-btn a {
    margin-top: 10px;
    display: block;
  }

  .date-time {
    position: relative;
  }

  .product-page-change .nav-link {
    padding: 20px 15px !important;
  }

  .tab-content .tab-pane {
    padding: 0 !important;
  }

  .multi-list ul {
    margin-bottom: 50px;
  }

  #filter-buttons:hover {
    color: #fff;
    cursor: pointer;
  }

  .filter {
    margin: 0 0 0 15px !important;
    padding-bottom: 20px;
  }

  .path-stat-circle {
    width: 45px !important;
    height: 45px !important;
  }

  .path-stat-circle img {
    height: 30px !important;
  }

  .path-stat-wrp {
    width: 74px !important;
  }

  .path-stats {
    background: #f7f7f769;
    padding: 0px 3px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .path-stat-wrp h5 {
    font-size: 10px !important;
  }

  .popular-tests-tab ul {
    max-width: 1140px;
    margin-bottom: 0 !important;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    width: 100%;
  }

  .path-banner-content {
    background: #f7f7f78c;
    border-radius: 5px;
    padding: 8px 8px;
    margin-bottom: 6px;
  }

  .top-text.removal_top h6 {
    display: none;
  }

  .top-text.removal_top {
    min-height: 40px;
  }

  .top-text.removal_top .second-part {
    position: relative;
    top: 0px;
  }

  .second-part.wishlist_ul ul li img {
    max-width: 15px;
    width: 100%;
    object-fit: contain;
    margin-right: 5px;
    vertical-align: middle;
  }

  a.text-uppercase.border-right-custom.text-dark img {
    display: none;
  }

  .header-part {
    padding: 0px 0px !important;
  }

  .main-Search {
    right: 60px;
    top: 7px;
  }

  .rate {
    max-width: 340px !important;
  }

  .input-group.input-width input {
    height: 35px !important;
  }

  .menu-logo {
    height: 36px !important;
    margin-left: 0px;
    padding-top: 7.5px;
  }

  .category-sort {
    margin-bottom: 10px !important;
  }

  .Category-section {
    min-height: 240px !important;
    width: 100%;
  }

  .img-product {
    min-height: 150px;
  }

  .text-center.img-product img {
    height: 150px;
  }

  .down-section {
    height: 90px !important;
  }

  .tab-content {
    margin-top: 118px !important;
  }

  .footer-section {
    padding: 45px 0px 20px;
  }

  .footer-link h5 {
    padding-bottom: 0px;
    font-size: 12px;
    line-height: 21px;
  }

  .footer-link a {
    font-size: 13px;
    color: #dfdfdf;
    margin-bottom: 5px;
  }

  .footer-link p {
    font-size: 12px !important;
    line-height: 20px;
  }

  .details {
    margin: 0px 2.5px;
    padding: 10px 6px 0px;
  }

  .background-info {
    padding: 30px 0;
  }

  .review-line .nav-item {
    margin-bottom: 5px;
  }

  .infomation-part h3 {
    font-size: 15px;
  }

  .infomation-part.mt-4 p {
    font-size: 14px !important;
    text-align: justify;
  }

  .path-stats ul {
    margin-left: 0px;
  }

  .barnd p {
    font-size: 15px !important;
    padding-bottom: 20px;
  }

  .people-about {
    padding: 0px 0;
  }

  .name-pate h3 {
    font-size: 19px;
    margin: 25px 0px;
  }

  .people-content p {
    font-size: 14px !important;
    line-height: 23px;
    text-align: justify;
  }

  .shopping-cart h2 {
    font-size: 23px;
    text-align: left;
    margin-bottom: 15px;
  }

  .add-product-list h5 {
    font-size: 14px;
    text-align: center;
  }

  .add-product-list .sp-quantity {
    margin: 13px 0;
  }

  .sp-plus {
    border: 1px solid #cbcbcb;
  }

  .remove-move a {
    font-size: 13px;
  }

  .sp-input {
    border: 1px solid #e1e1e1;
  }

  .add-product-list {
    margin-bottom: 0px;
  }

  .pro-det .pl-4.pd-remove {
    background: transparent;
    border: 0px;
    border-radius: 0px;
  }

  .our-associates {
    background: #f3f9ec;
    padding: 50px 0px;
  }

  .nw-heading h4 {
    font-size: 24px;
    text-align: center;
  }

  section.our-associates.our-asso .wapper {
    margin-bottom: 20px;
    text-align: center;
  }

  section.our-associates.our-asso .wapper img {
    max-width: 220px;
    object-fit: contain;
  }

  .all-test {
    padding: 50px 0px;
  }

  .top-product.height-product {
    margin-top: 20px;
  }

  section.sec-mar {
    margin-top: 130px;
  }

  .custom-form-field input {
    height: 40px;
    font-size: 13px;
  }

  .custom-form-field label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .react-datepicker-popper {
    transform: translate3d(30px, 113px, 0px) !important;
  }

  .stepwizard {
    margin-bottom: 0px;
  }

  .edit-part p {
    font-size: 13px !important;
  }

  .date-setting h5 {
    font-size: 15px;
    text-align: left;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 25px !important;
    text-align: center;
    margin: 0px !important;
    font-size: 12px !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 12px !important;
    margin-bottom: 5px;
  }

  .react-datepicker__input-container input,
  .react-datepicker__input-container input::placeholder {
    font-size: 12px !important;
  }

  .bg-test-bot {
    background: #ebebeb;
    border: 1px solid #dddbdb;
    padding: 12px 5px;
    border-top: 0px;
  }

  .sec-mar {
    margin-top: 120px;
  }

  .title-name-list h3 {
    font-size: 15px;
  }

  .modal-body.new-part-enter {
    padding: 20px;
    max-width: 320px;
    width: 100%;
  }

  .modal-title {
    font-size: 15px;
  }

  textarea#review {
    max-width: 300px;
    margin: auto;
  }

  .view-details {
    position: absolute;
    left: -8px;
    right: 0;
    bottom: -11px;
    padding: 15px 20px;
  }

  .second-nav-wrp .navbar-toggler.collapsed {
    display: block !important;
  }

  .second-nav-wrp .nav-link:first-child {
    padding-left: 15px !important;
  }

  .second-nav-wrp .navbar-toggler {
    padding: 0px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: relative;
    width: 90%;
    background-color: #fff !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu a {
    color: #182d48 !important;
  }

  .dfo-wrapper {
    padding-top: 50px;
  }

  .whatsapp-banner-content {
    background-size: cover !important;
  }

  .wb-banner-content h1 {
    font-size: 32px;
  }

  .second-part a {
    font-size: 13px !important;
    display: block;
    min-width: 75px;
    border: unset !important;
  }

  .wish-cart-ul li {
    display: flex;
    white-space: nowrap;
    padding-left: 10px;
    min-width: 77px;
  }
  .wish-cart-ul li img {
    width: 15px;
  }
  .tab-product-price.mb-2 {
    width: 100%;
  }

  .add-product-list .price {
    position: relative !important;
    right: 0 !important;
    top: 0 !important;
  }

  .no-data.text-center.w-100 {
    margin-top: 60px;
  }

  .second-part a sup {
    font-size: 12px;
    position: relative;
    top: -10px;
  }

  .detail-outer {
    display: block !important;
  }

  .products-owl .product img {
    object-fit: contain;
    height: 150px !important;
  }

  .product {
    min-height: 200px;
  }

  .service-aside .p-4 {
    border: unset !important;
  }

  .date-setting {
    margin-bottom: 40px;
    margin-top: 10px;
    margin-left: -15px;
  }
}

@font-face {
  font-family: RNSSanz-Normal;
  src: url("../../fonts/RNSSanz-Normal.ttf");
}

@font-face {
  font-family: RNSSanz-medium;
  src: url("../../fonts/RNSSanz-Medium.ttf");
}

@font-face {
  font-family: RNSSanz-bold;
  src: url("../../fonts/RNSSanz-Bold.ttf");
}

@font-face {
  font-family: RNSSanz-x-bold;
  src: url("../../fonts/RNSSanz-ExtraBold.ttf");
}

html,
body {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden !important;
  font-weight: 400;
  color: #353535;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  outline: 0;
  background: none !important;
}

a:hover {
  text-decoration: none;
  color: inherit !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.item:hover {
  cursor: pointer;
}

p {
  font-size: 16px !important;
  color: #303030;
  font-family: RNSSanz-medium !important;
}

a {
  font-family: RNSSanz-medium !important;
}

button:focus {
  outline: none;
  border: none;
  outline: 0;
}

a:hover {
  text-decoration: none !important;
  cursor: pointer;
  transition: all 0s ease;
}

button:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  outline: 0;
}

header {
  width: 100%;
  float: left;
}

section {
  width: 100%;
  overflow: hidden;
}

h3 {
  font-family: RNSSanz-medium !important;
}

h2 {
  font-family: RNSSanz-x-bold !important;
}

.overflow-hide {
  overflow: initial;
  margin-top: 30px;
}
.nav-menu {
  display: flex;
}
.nav-d-lg {
  margin-top: 15px;
}
.no-scroll {
  overflow: hidden !important;
}

.container {
  max-width: 1400px !important;
}
ul.pl-0.mb-0.list-inline.list-block.d-flex.wish-cart-ul.show-hide-nav {
  display: none !important;
}
/* .p-4.new-height.our-scrvice-height {
  height: 200px;
} */

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  background: #fff;
  border-bottom: 1px solid #eee;
}

.home-top-banner {
  margin-top: 200px;
}

.banner-outer-top {
  width: 100%;
  float: left;
  margin-top: 180px;
}

.caro-img-outer {
  width: 100%;
  height: 630px;
}

.caro-img-outer img {
  object-fit: cover;
  height: 630px;
}

.product-name h3 {
  max-width: inherit !important;
  margin-bottom: 20px;
}

.custom-width {
  max-width: 1260px !important;
}

.item {
  cursor: pointer;
}

.highlight {
  overflow-y: hidden;
}

.header-part {
  display: flex;
  justify-content: start !important;
  padding: 10px 0px;
}

.bottom-pd {
  padding-bottom: 25px;
}

.contact-info li a {
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.border-right-custom {
  color: #000;
}

.explore-text {
  color: #294a6c !important;
  font-weight: 700 !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-style: italic;
}

.text-uppercase.border-right-custom {
  padding: 0 6px;
  border-right: 3px solid #e8e8e8;
  color: #000 !important;
  font-weight: 400 !important;
}

.text-uppercase.border-right-custom img {
  padding-right: 6px;
  padding-bottom: 5px;
  height: 20px;
}

.text-center.health-text {
  font-size: 14px !important;
  color: #182d48;
  letter-spacing: 0;
  text-align: center;
  font-weight: 700;
  margin-top: 5px;
}

.second-part {
  display: flex;
}

.nav-link.color {
  display: none;
}

.cart-icon li {
  padding-right: 15px;
}

.cart-quantity {
  top: -12px;
  font-weight: 900;
  font-size: 14px;
}

.btn-hover {
  background-color: #182d48;
  padding: 13px 30px;
  margin: 0 6px;
  font-weight: 600;
}

.nav-space .nav-item.nav-link {
  padding-bottom: 0px !important;
  color: #182d48 !important;
  font-size: 14px !important;
  white-space: nowrap;
}

.nav-link {
  font-size: 15px;
  color: #182d48;
  letter-spacing: 0;
  padding: 0 30px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-link {
  color: #182d48;
}

.vendor-part a {
  color: #182d48;
  border: 2px solid #182d48;
  border-radius: 10px;
  padding: 6px 0px;
  margin-bottom: 14px;
  display: block;
  max-width: 250px;
  margin: 0 auto;
  font-size: 18px;
}

.vendor-part a:hover {
  background-color: #182d48;
  color: #fff !important;
}

.vendor-part h5 {
  color: #000;
  margin-bottom: 30px;
  font-size: 22px;
}

.close.close-btn {
  position: absolute;
  right: -14px;
  top: -17px;
  background-color: #182d48;
  border-radius: 80px;
  width: 40px;
  height: 40px;
  opacity: 1;
}

.close.close-btn span {
  position: relative;
  top: -2px;
  right: 0px;
  color: #fff;
}

.nav-link.side-padding {
  padding: 0px 15px 0px 30px !important;
}

.popup-here {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.min-height {
  height: 550px;
}

.vendor-main {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
  border-radius: 12px !important;
}

.vendor-part {
  padding: 50px;
  border: 0;
}

.modal-vendor {
  border-radius: 20px;
  border: 0;
}

.bg-white-part {
  background: #fff;
}

/*  */

@media screen and (min-device-width: 375px) and (max-device-width: 550px) {
  .close.close-btn {
    right: 13px;
  }
}

.wapper-product .product img {
  object-fit: contain;
}

.btn-hover.hvr {
  border: 2px solid #182d48 !important;
  background: transparent;
  color: #182d48 !important;
  font-weight: 600;
  padding: 13px 30px;
}

.about-btn {
  background: #182d48;
  color: #fff;
  font-weight: 600;
  padding: 15px 30px;
}

.about-btn:hover {
  border: 1px solid #182d48;
  color: #182d48 !important;
  padding: 15px 30px;
  background-color: transparent;
}

.btn-hover.hvr:hover {
  background: #182d48;
  color: #fff !important;
  border: 2px solid #182d48;
}

.btn-hover:hover {
  border: 2px solid #182d48 !important;
  background: transparent;
  color: #182d48 !important;
}

.carousel-caption img {
  width: 200px;
}

.carousel-caption {
  position: absolute;
  right: 0 !important;
  bottom: 20px !important;
  left: 10% !important;
  z-index: 10;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #fff;
  text-align: left;
  top: 30%;
  width: 40%;
}

.menu-logo {
  height: 50px;
  object-fit: contain;
}

.navigation {
  padding-top: 20px;
  padding-bottom: 10px;
}

.navigation .nav-link {
  font-size: 15px;
  color: #353535;
  font-weight: 500;
}

.navigation .nav-link:hover {
  color: #2fa9c2 !important;
}

.navigation .nav-item {
  margin-left: 35px;
}

.home-text-wrapper {
  text-align: center;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}

.carousel-indicators .active {
  background-color: #182d48;
}

.text-custom {
  margin-top: 30px;
  text-align: left;
}

.text-custom h2 {
  font-size: 14px;
  color: #182d48;
  letter-spacing: 1.5px;
  line-height: 20px;
  font-weight: 500;
}

.review img {
  width: 200px;
  position: relative;
  left: -40px;
}

.text-custom h3 {
  font-size: 58px;
  color: #fff;
  letter-spacing: 0;
  margin-bottom: 0px;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 0;
}

.text-custom h4 {
  font-size: 17px;
  color: #ececec;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 0;
  padding: 0px 0;
  font-weight: 500;
  margin: 15px 0;
}

.text-custom img {
  width: 200px !important;
  object-fit: contain;
}

.modal-body.new-part-enter {
  padding: 40px;
}

.text-custom p {
  font-size: 14px;
  color: #182d48;
  letter-spacing: 0.3px;
  margin-bottom: 30px;
  font-weight: 400;
  font-style: italic;
  line-height: 23px;
}

.text-custom a {
  background: #d3f8f7;
  color: #182d48 !important;
  font-size: 18px;
  letter-spacing: 1.29px;
  padding: 15px 35px;
  font-weight: 500;
  display: inline-block;
}

.text-custom a:hover {
  background: #182d48;
  color: #fff !important;
}

/* about us */

.about-section {
  background-color: #182d48;
  padding: 80px 0px;
}

.save-ansave {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.button-custom.btn-width-save {
  width: 45%;
}

.p-about {
  margin-bottom: 50px;
  font-weight: 400;
  line-height: 26px;
}

.about {
  width: 80%;
  margin: 0 auto;
}

.store {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 25px;
}

.store p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 25px;
  line-height: 19px;
}

/* .new-height {
  height: 270px;
} */

.product-main-div {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 9;
}

.r-div {
  position: relative;
  /* z-index: -1; */
}

.order-img-width img {
  width: 150px;
}

.price a {
  font-size: 20px;
  color: #182d48;
}

.text-empty {
  text-align: center;
}

.product-img-static {
  padding: 20px 0;
  position: relative;
}

.store a {
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}

.about.text-center.text-white h6 {
  font-size: 18px;
  font-weight: 700;
}

.pagination-products {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* about us */

/* headphone */

.bg-headphone {
  background-color: rgba(70, 200, 120, 0.2);
  border-radius: 5px;
  padding: 30px 0;
}

.media {
  padding-left: 85px;
}

.media h5 {
  font-size: 20px;
  color: #182d48;
  font-weight: 700;
}

.media p {
  font-size: 15px;
  color: #494949;
  letter-spacing: 0.47px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0;
}

.number li a {
  font-size: 20px;
  color: #182d48;
  font-weight: 700;
}

.number {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.or {
  font-size: 15px;
  color: #494949;
  letter-spacing: 0.47px;
}

.top-m {
  margin-top: 80px;
}

/* headphone */

/* top-brands */

.top-product {
  padding: 90px 0;
}

.top-brands {
  padding: 75px 0px;
  position: relative;
}

.barnd h2 {
  font-size: 28px;
  color: #182d48;
  font-weight: 700;
  font-family: RNSSanz-x-bold !important;
}

.barnd p {
  font-size: 15px;
  color: #494949;
  letter-spacing: 0.47px;
  padding-bottom: 25px;
  font-weight: 400;
  margin-bottom: 0px;
}

.side-admin.nav-link:hover {
  cursor: pointer !important;
}

/* top-brands */

.owl-carousel {
  display: inline-flex !important;
  width: 100%;
  z-index: 1;
  justify-content: space-between;
}

.owl-next span {
  display: none;
}

.owl-prev span {
  display: none;
}

.top-brands-owl .owl-prev::before {
  content: "";
  background: url("/assets/img/pre.png") no-repeat;
  width: 35px;
  height: 66px;
  position: absolute;
  background-position: center;
  border: 1px solid #eee;
  margin: 0 -30px;
  left: 0;
  border-radius: 2px;
  background-color: #fff;
}

.top-brands-owl .owl-next::after {
  content: "";
  background: url("/assets/img/next.png") no-repeat;
  background-color: #66cc8d;
  width: 35px;
  height: 66px;
  position: absolute;
  background-position: center;
  border-radius: 2px;
}

.products-owl .owl-nav {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  top: 170px;
}

.owl-theme .owl-nav {
  margin-top: 0px !important;
  right: 30px;
}

.top-brands-owl .owl-prev::before {
  content: "";
  background: url("/assets/img/pre.png") no-repeat;
  width: 35px;
  height: 66px;
  position: absolute;
  background-position: center;
  border: 1px solid #eee;
  margin: 0px;
  left: -20px;
  border-radius: 2px;
  background-color: #fff;
}

.top-brands-owl .owl-next::after {
  content: "";
  background: url("/assets/img/next.png") no-repeat;
  background-color: #66cc8d;
  width: 35px;
  height: 66px;
  position: absolute;
  background-position: center;
  border-radius: 2px;
  right: -20px;
}

.new {
  display: inline-flex !important;
  width: 100%;
  z-index: 1;
  justify-content: space-between;
}

.health h2 {
  font-size: 28px;
  color: #182d48;
  margin-bottom: 25px;
  font-weight: 700;
}

.health-owl .owl-nav {
  margin-top: 20px !important;
  right: 30px;
}

.health-owl .owl-prev::before {
  content: "";
  background: url("/assets/img/pre.png") no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0%;
  background-position-y: 0%;
  width: 35px;
  height: 35px;
  position: absolute;
  background-position: center;
  border: 1px solid #eee;
  margin: 0 -30px;
  left: 0;
  border-radius: 2px;
  background-color: #fff;
}

.health-owl .owl-next::after {
  content: "";
  background: url("/assets/img/next.png") no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0%;
  background-position-y: 0%;
  background-color: #66cc8d;
  width: 35px;
  height: 35px;
  position: absolute;
  background-position: center;
  border-radius: 2px;
}

.service-bg {
  background-color: #edfaf1;
}

.service h1 {
  font-size: 19px;
  color: #182d48;
  font-weight: 600;
  margin-top: 40px;
  font-family: RNSSanz-bold;
}

.people-name {
  display: flex;
  align-items: center;
}

.service p {
  font-size: 16px;
  color: #494949;
  text-align: center;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 0;
}

.service-bg {
  padding: 80px 0px;
}

.people-about {
  padding: 80px 0;
}

.people-name {
  display: flex;
}

.name-pate h3 {
  font-size: 20px;
  color: #182d48;
  font-weight: 700;
  font-family: RNSSanz-bold;
}

.name-pate h4 {
  font-size: 15px;
  color: #182d48;
  font-weight: 400;
}

.new-height.our-scrvice-height:hover {
  cursor: pointer;
}

.service-aside:hover {
  cursor: pointer;
}

.people-content p {
  font-size: 16px;
  color: #494949;
  line-height: 28px;
  margin-top: 25px;
  font-weight: 400;
}

.add-product.new-height-product img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  cursor: pointer;
}

.new-product-width h3 {
  max-width: 290px !important;
}

.people-owl .blog-page .owl-carousel .owl-item img {
  width: 100%;
  height: 297px;
}

.people-owl .owl-carousel .owl-item img {
  width: 50px;
  height: 50px;
}

.people-about .barnd {
  margin-bottom: 50px;
}

.products-owl .owl-prev::before {
  content: "";
  background: url("/assets/img/pre.png") no-repeat;
  width: 39px;
  height: 66px;
  position: absolute;
  background-position: center;
  border: 1px solid #eee;
  left: -20px;
  top: -17px;
  border-radius: 2px;
  background-color: #fff;
}

.products-owl .owl-next::after {
  content: "";
  background: url("/assets/img/next.png") no-repeat;
  background-color: #66cc8d;
  width: 39px;
  height: 66px;
  position: absolute;
  background-position: center;
  border-radius: 2px;
  right: -20px;
  top: -17px;
}

.top-brands-owl .owl-nav {
  top: 70px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
}

.top-brands-owl.new-owl-client .owl-nav {
  top: 80px;
}

.top-brands-owl.new-owl-client img {
  width: 100% !important;
}

.people-owl .owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}

.people-owl .owl-prev::before {
  content: "";
  background: url("/assets/img/pre.png") no-repeat;
  width: 35px;
  height: 35px;
  position: absolute;
  background-position: center;
  border: 1px solid #eee;
  margin: 0 -50px;
  border-radius: 2px;
  background-color: #fff;
}

.people-owl .owl-nav {
  position: absolute;
  right: 26px;
  top: -120px;
}

/* .top-brands-owl img {
  width: 250px !important;
  object-fit: contain;
} */

.people-owl .owl-next::after {
  content: "";
  background: url("/assets/img/next.png") no-repeat;
  background-color: #66cc8d;
  width: 35px;
  height: 35px;
  position: absolute;
  background-position: center;
  border-radius: 2px;
}

.footer-section {
  background-color: #1a1a1a;
  padding: 50px 0px;
  width: 100%;
  padding-bottom: 50px;
  float: left;
  margin-top: 25px;
}

.btn.new_text_btn {
  background: #69cd90;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  width: 135px;
  line-height: 40px !important;
  padding: 0 !important;
}

.new_text_btn:hover {
  color: #fff !important;
}

/* .products-owl.owl-carousel .owl-item img {
  width: auto;
} */

.popular-tests-tab .nav-link {
  font-size: 13px;
  padding: 10px 10px !important;
}

.footer-link h5 {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1px;
  padding-bottom: 10px;
  font-weight: 700;
}

.footer-link a {
  font-size: 14px;
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
}

.footer-link p {
  font-size: 13px !important;
  line-height: 20px;
}

.footer-link p {
  font-size: 16px;
  color: #ffffff;
  line-height: 15px;
  display: block;
  margin-bottom: 14px;
  font-weight: 400;
}

.footer-link input {
  /* background-color: rgba(0, 0, 0, 0.23); */
  border-radius: 4px;
  height: 40px;
  margin-bottom: 20px;
  color: black;
  border: none;
  font-size: 13px;
  border-radius: 5px !important;
}

i.fa.fa-paper-plane:hover {
  cursor: pointer;
}

.footer-link input:focus {
  z-index: none;
}

.custom-btn-send {
  background: top;
  border: none;
  color: black;
  position: absolute;
  top: 9px;
  right: 4px;
  z-index: 9999;
}

.OFFERS {
  padding-bottom: 10px !important;
}

.soical img {
  margin: 0 14px;
}

.soical {
  padding-bottom: 29px;
}

.view img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.view {
  max-height: 640px;
  width: 100%;
}

.product {
  border: 1px solid #dfdfdf;
  border-radius: 15px 15px 0px 0px;
  background: #fff;
  padding: 10px;
  overflow: hidden;
  margin: 0px 0px;
}

.product:hover {
  box-shadow: 0 10px 10px 0 rgba(231, 231, 231, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.products-owl .product img {
  /* width: 200px !important; */
  object-fit: contain;
  height: 294px;
}

/* .nav-item:active {
  border-bottom: 3px solid #182d48;
  padding-bottom: 20px;
} */

.more-btn a {
  font-weight: 700;
}

.review-product {
  color: #66cc8d;
  font-weight: bolder;
}

.details {
  display: flex;
  justify-content: space-between;
  margin: 0px 2.5px;
  padding: 30px 6px 0px;
  border-bottom: 0px;
  border-top: 0;
}

.rate {
  padding: 0px 0px;
  width: 100%;
  margin: auto;
  padding-bottom: 10px;
  border-top: 0;
}

.nav-item.web-hide.nav-link.active {
  display: none;
}

.product-name h3 {
  max-width: 310px;
}

.details h3 {
  font-size: 16px;
  color: #182d48;
  font-weight: 700;
  font-family: RNSSanz-medium !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
}

.plus {
  background: #66cc8d;
  color: #fff;
  width: 24px;
  height: 23px;
  text-align: center;
  line-height: 25px;
}

.rate p {
  font-size: 16px;
  color: #1d1d1d;
  letter-spacing: 0;
  font-weight: 700;
  font-family: RNSSanz-bold !important;
}
.align {
  position: absolute !important;
  top: 2px !important;
  right: -7px !important;
}
.fa.fa-heart {
  float: right;
  position: absolute;
  top: 14px;
  right: 26px;
  font-size: 20px;
  color: red;
  z-index: 3;
}
.fa-heart-o {
  float: right;
  position: absolute;
  top: 14px;
  right: 26px;
  font-size: 20px;
  color: #000;
  z-index: 3;
}
.product.fea .fa.fa-heart-o {
  float: right;
  position: absolute;
  top: 14px;
  right: 21px;
  font-size: 24px;
  color: #000;
  z-index: 3;
}

.privacy-page.min-height img {
  height: 100vh;
  object-fit: cover;
}

.date-setting {
  margin-bottom: 40px;
}

.phone-change {
  display: block;
}

.second-part.new-show {
  display: none;
}

.my-order-flex .producrt-list {
  width: 80%;
}

/* .wapper-product .product i {
  position: absolute;
  right: 0px;
} */

.service-aside p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 50px;
}

.service.text-center {
  margin-right: 3px;
}

.wapper-product .product .fa.fa-heart-o {
  color: black;
  float: right;
  position: absolute;
  top: 14px;
  right: 25px;
  font-size: 24px;
}

.about-section h2 {
  font-weight: 700;
  font-size: 32px;
}

.main-product {
  position: relative;
}

.product-page img {
  width: 100%;
  object-fit: cover;
}

.product-page.larger-product img {
  width: 100%;
  height: 514px;
}

.product-page {
  position: relative;
  border-radius: 15px;
  display: block;
  overflow: hidden;
  margin-bottom: 28px;
}

.product-conetnt {
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 430px;
  color: #fff;
}

.product-conetnt h2 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}

.banner-dynamic {
  margin-top: 50px;
}

.banner-dynamic img {
  width: 100%;
}

.product-conetnt.big-pic {
  left: 30px;
  bottom: 10px;
}

.our-product-owl .owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}

/* .our-product-owl .owl-prev::before {
  content: "";
  background: url("/assets/img/pre.png") no-repeat;
  width: 35px;
  height: 35px;
  position: absolute;
  background-position: center;
  border: 1px solid #eee;
  margin: 0 0px;
  right: 15px;
  border-radius: 2px;
  background-color: #fff; 
} */

.our-product-owl .owl-nav {
  top: -80px;
  position: absolute;
  right: 36px;
}

/* 
.our-product-owl .owl-next::after {
  content: "";
  background: url("/assets/img/next.png") no-repeat;
  background-color: #66cc8d;
  width: 35px;
  height: 35px;
  position: absolute;
  background-position: center;
  border-radius: 2px;
} */

.our-product-owl .owl-nav {
  top: -90px;
}

.our-product {
  background: #fafafa;
  padding: 70px 0;
}

.popup-img h2 {
  font-size: 18px;
  color: #182d48;
  padding-top: 20px;
}

.popup-img h1 {
  color: #182d48;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: RNSSanz-bold !important;
}

.popup-img input {
  height: 48px;
  border: 1px solid #182d48;
}

.popup-btn {
  background-color: transparent;
  border: 1px solid #182d48;
  border-right: 0;
}

.continue a {
  color: #fff !important;
  line-height: 54px;
  background: #182d48;
  height: 55px;
  text-align: center;
  margin-top: 30px;
  width: 100%;
  display: block;
}

.continue p span {
  color: #66cc8d;
  text-align: right;
}

.main-popup-wd {
  max-width: 1000px;
}

.popup-img {
  text-align: center;
  border-right: 1px solid #e2e2e2;
  height: 100%;
  padding-right: 40px;
  padding: 40px;
}

.modal-content.popup-main {
  overflow: hidden;
}

.otp-input input {
  margin: 10px;
  border-radius: 0;
  text-align: center;
  width: 45px !important;
  height: 45px;
  outline: -webkit-focus-ring-color auto 1px !important;
}

.otp-input input:focus {
  outline: auto 1px !important;
}

.tab-back {
  background-color: #f8f9fb;
  height: 100%;
  width: 100%;
  padding: 40px 0 36px 10px;
  min-height: 324px;
}

.tab-back .nav-link {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #182d48;
  background-color: transparent;
}

.heading-tab h3 {
  font-size: 26px;
  font-weight: 700;
  color: #182d48;
}

.custom-form-field {
  margin-bottom: 30px;
}

.tab-flied {
  padding-left: 40px;
}

.custom-form-field label {
  font-weight: 600;
  color: #182d48;
  font-size: 16px;
  margin-bottom: 20px;
}

.custom-form-field input {
  height: 60px;
  border-radius: 0px;
  border: 1px solid #182d48;
  color: #292929;
  font-size: 15px;
}

.custom-form-field a {
  background: #182d48;
  width: 150px;
  display: block;
  text-align: center;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.custom-form-field .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #182d48;
  outline: 0;
}

.edit-profile {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.edit i {
  background-color: #66cc8d;
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
}

.sec-profile {
  position: relative;
  left: -252px;
}

.edit-profile-none {
  display: none;
}

.custom-form-field.fill-content input {
  background: #f9f9f9;
  color: #333;
}

.edit-profile-content {
  color: #66cc8d;
}

.edit a {
  color: #66cc8d;
}

.select-cus select {
  appearance: none;
  height: 60px !important;
  border-radius: 0px;
  border: 1px solid #182d48;
  font-size: 15px;
}

.select-cus::after {
  content: url(/assets/img/down-icon.png);
  position: absolute;
  right: 29px;
  top: 15px;
  z-index: 0;
  background-repeat: no-repeat;
  z-index: 3;
}

.custom-form-field.fill-content .form-group {
  margin-bottom: 30px;
}

.edit-part h6 {
  color: #182d48;
}

.edit-part p {
  color: #182d48;
  font-weight: 200;
}

.edit-part {
  background: #f8f9fb;
  padding: 20px;
  position: relative;
}

.edit-block {
  position: absolute;
  right: 20px;
  top: 20px;
}

.edit-address-none {
  display: none;
}

.logout h2 {
  color: #182d48;
  margin-bottom: 15px;
  font-size: 22px;
}

.logout {
  padding: 80px 0;
}

.privacy-page {
  position: relative;
}

.privacy-page h1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-content: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 62px;
  font-weight: 700;
  margin-bottom: 0;
}

.privacy-content h2 {
  font-size: 30px;
  font-weight: bold;
  color: #182d48;
}

.privacy-content p {
  font-size: 18px;
  line-height: 36px;
  color: #182d48;
  margin-bottom: 30px;
}

.privacy-content li {
  font-size: 20px;
  line-height: 36px;
  color: #182d48;
}

.add-product-list {
  display: flex;
  margin-bottom: 20px;
}

.add-product-list .sp-quantity {
  margin-top: 0px;
}

.nav-item.web-hide.nav-link {
  display: none;
}

.add-product-list h5 {
  color: #182d48;
  font-size: 18px;
}

.sp-minus {
  width: 35px;
  height: 35px;
  float: left;
  text-align: center;
  border: 1px solid #eee;
}

.sp-input {
  width: 35px;
  height: 35px;
  float: left;
  margin: 0 30px;
}

.sp-plus {
  width: 35px;
  height: 35px;
  float: left;
  text-align: center;
  background: #66cc8d;
  color: #fff;
}

.sp-input input {
  width: 30px;
  height: 31px;
  text-align: center;
  font-family: "ProximaNova Bold", Helvetica, Arial;
  border: none;
}

div#pills-tabContent {
  margin-top: 20px !important;
}

.details.detail-bg {
  background: #f3f3f3;
  padding: 15px 15px;
  border: 1px solid #e5e5e5;
}

.sp-input input:focus {
  border: 1px solid #e1e1e1;
  border: none;
}

.sp-minus a,
.sp-plus a {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  font-size: 20px;
}

.main-add {
  width: 400px;
}

.remove-move {
  margin-top: 20px;
}

.remove-move a {
  color: #182d48;
  font-size: 17px;
  padding-right: 30px;
}

.product-list-main {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.sp-quantity {
  margin-top: 20px;
  padding-bottom: 36px;
}

.wishlist h3 {
  font-size: 22px;
  color: #182d48;
  margin-bottom: 30px;
}

.wishlist input {
  border: 1px solid #182d48;
  border-radius: 0px;
  height: 46px;
  width: 75%;
}

.wishlist label {
  font-size: 18px;
  color: #182d48;
  font-weight: 500;
}

.wishlist button {
  background-color: #182d48;
  border: 0;
  color: #fff;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
}

.apply-filed {
  display: flex;
  justify-content: space-between;
}

.wishlist.sec-part {
  text-align: right;
  margin-top: 37px;
}

.wishlist.sec-part button {
  background-color: #66cc8d;
  padding: 14px 50px;
}

.wishlist.sec-part h3 {
  font-size: 24px;
  color: #182d48;
  margin-bottom: 30px;
}

.sroll-side {
  width: 100%;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row::before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 70%;
  height: 1px;
  border: 1px dotted #182d48;
  right: 10%;
  left: 0px;
  text-align: center;
  margin: 0 auto;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center !important;
  padding: 7px 0 !important;
  font-size: 12px !important;
  line-height: 1.428571429 !important;
  border-radius: 15px !important;
}

.btn-circle.active {
  background-color: #182d48;
  color: #fff;
}

.stepwizard-step.active a {
  background-color: #182d48;
}

.edit-part input {
  position: absolute;
  left: 23px;
  top: 23px;
}

.redio {
  padding-left: 30px;
}

.product-img-details {
  display: flex;
  color: #182d48;
  align-items: center;
  margin-bottom: 20px;
}

.product-img-details p {
  margin-left: 20px;
}

.price-details p {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 30px;
}

.total-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.total-list h5 {
  font-size: 16px;
  color: #182d48;
}

.tab-left-content {
  position: relative;
  background: #f8f9fb;
  width: 310px;
  margin-right: -100px;
  left: -170px;
  height: 243px;
}

.tab-left-content .nav-link {
  color: #182d48;
  font-size: 16px;
  padding: 24px 36px !important;
  border-bottom: 1px solid #e9e9e9;
}

.tab-left-content .nav-link.active {
  border-left: 5px solid #182d48;
  border-radius: 0;
}

.tab-three-content {
  width: 600px;
}

.form-group.cvv-input input:nth-child(1) {
  width: 80%;
  padding: 0px 15px;
}

.form-group.cvv-input input:nth-child(2) {
  width: 15%;
  float: right;
  padding: 0px 15px;
}

.or-part {
  text-align: center;
  margin-bottom: 15px;
  color: #182d48;
}

.btn.btn-circle.btn-custom.btn-default {
  background: #cfcfcf;
  border: none;
}

.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  color: #fff !important;
  background-color: #182d48 !important;
  border-color: #182d48 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.cart-bg h1 {
  color: #182d48;
  padding: 40px 0px;
  margin-bottom: 0;
}

.cart-bg button {
  border: 0;
  background: #66cc8d;
  color: #fff;
  padding: 14px 30px;
}

.cart-bg button:hover {
  cursor: pointer;
}

.btn-custom {
  background: #cfcfcf;
}

.btn-custom.active {
  background: #cfcfcf;
}

.stepwizard-custom p {
  font-size: 18px;
  font-weight: 600;
  color: #182d48;
  margin-top: 10px;
}

.stepwizard-step a {
  font-weight: bold;
  color: #fff;
  background-color: #cfcfcf;
  border: 0;
}

.stepwizard-step.custom-step-part {
  position: relative;
  left: 50px;
}

.main-content-shop {
  border: 1px solid #d2ebff;
}

.shop-content {
  padding: 30px;
}

.shop-content h2 {
  font-size: 18px;
  color: #182d48;
  font-weight: 600;
}

.shop-content p {
  font-size: 15px !important;
}

.shop-content h5 {
  font-size: 15px;
  line-height: 20px;
}

.shop-content a {
  color: #182d48;
}

.hover-get i {
  color: #fff;
  font-size: 52px;
}

.hover-get {
  color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.47);
}

.hover-get a {
  color: #fff;
}

.hover-algin {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.hover-get {
  opacity: 0;
}

/* .main-content-shop:hover .hover-get {
  opacity: 1;
}

.main-content-shop:hover {
  cursor: pointer;
} */

.corporate-content p {
  font-size: 20px;
  line-height: 36px;
  color: #252525;
  /* margin-bottom: 40px; */
}

.corporate-content li {
  font-size: 20px;
  color: #252525;
  margin-bottom: 20px;
  font-family: RNSSanz-medium !important;
}

.cororate {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.service-aside {
  border: 1px solid #d2ebff;
  margin-bottom: 20px;
  position: relative;
}

.add-product-list .service-aside p {
  margin-bottom: 30px;
}

.services h3 {
  color: #182d48;
  font-size: 22px;
}

.services h4 {
  font-size: 16px;
}

.view-details a:nth-child(1) {
  font-size: 16px;
  color: #182d48;
  font-weight: 600;
  font-family: RNSSanz-medium !important;
  z-index: 9999999;
  position: relative;
}

.soical-part li a:hover {
  color: #fff !important;
}

.pagination-custom .page-link {
  border: none;
}

.pagination-custom .page-link {
  color: #333 !important;
}

.pagination-custom .page-link.active {
  background: #66cc8d;
  color: #fff !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pathology h2 {
  font-size: 32px;
  color: #182d48;
  font-weight: 700;
}

.add-bg button {
  border: 0;
  background: #66cc8d;
  color: #fff;
  padding: 14px 30px;
  margin-top: 20px;
}

.review-line:active .nav-item:active {
  border-bottom: 0px solid #182d48;
  padding-bottom: 0px;
}

.card-img img {
  width: 100px;
}

.plus:hover {
  cursor: pointer !important;
}

/* .pathology {
  display: flex;
  justify-content: space-between;
} */

.service-name h3 {
  font-size: 22px;
  color: #182d48;
  font-weight: 700;
}

.service-name {
  margin-bottom: 30px;
}

.service-name {
  margin-bottom: 40px;
}

.category-sort {
  background-color: #182d48;
  border-radius: 8px;
  margin-bottom: 30px;
}

.category-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.nav-btn {
  font-weight: 600 !important;
}

.dropdown.custom-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown_outside {
  position: absolute;
  background-color: #fff;
  left: 29px;
  top: 36px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 999;
  padding-bottom: 10px;
}
#dropdown-split-basic {
  padding-right: 0.5625rem;
  padding-left: 0px;
  margin: -2px 0 0 -7px;
  display: flex;
}
.sort-sarech {
  background-color: rgba(24, 45, 72, 0.64) !important;
  width: 180px;
  height: 50px;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* float: right !important; */
  position: relative;
  top: 11px;
}

.Category-section {
  border-radius: 15px;
  border: 1px solid #eee;
  margin-bottom: 30px;
  min-height: 410px;
  overflow: hidden;
}

.product-img-details img {
  width: 170px;
}

.img-product {
  background: #fff;
  padding: 12px;
  min-height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.down-section {
  padding: 15px;
  background: #f8f9fb;
  height: 120px;
}

.left-side-content-tab {
  background: #f8f9fb;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  margin-top: 200px;
  height: calc(100vh - 200px);
  width: 300px;
}

.empty-text-bold {
  font-weight: bolder;
}

.left-side-content-tab .nav-link {
  text-transform: capitalize;
  color: #182d48;
  line-height: 35px;
}

.left-side-content-tab .nav-pills .nav-link.active {
  font-weight: normal;
}

.filter-head {
  color: #182d48;
  font-size: 19px !important;
  font-weight: 900;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-top: 15px;
}

.category-list a {
  color: #182d48;
}

.multi-list li {
  list-style: none;
}

.multi-list li a {
  font-size: 15px;
  color: #2d2d2d;
  font-weight: lighter;
}

.plus:hover {
  cursor: pointer;
}

.filter button {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  background-color: #182d48;
  border-radius: 7px;
  height: 50px;
}

.filter {
  margin: 0 15px;
  padding-bottom: 20px;
}

.custm-loader {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  text-align: center;
  z-index: 9999;
  padding-top: 25%;
  border-radius: 18px !important;
}

.custm-loader img {
  width: 100px;
}

.pagination-products {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 0 !important;
}

.pagination-products > .active > a {
  background-color: #182d48 !important;
  border-color: #182d48 !important;
  color: #fff;
}

.pagination-products > li > a {
  border: 1px solid #e4e4e4;
  padding: 6px 12px;
  outline: none;
  cursor: pointer;
  border-radius: unset !important;
}

.pagination-products > li:first-child > a {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.pagination-products > li:last-child > a {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.pagination-products > .active > a,
.pagination-products > .active > span,
.pagination-products > .active > a:hover,
.pagination-products > .active > span:hover,
.pagination-products > .active > a:focus,
.pagination-products > .active > span:focus {
  background-color: #182d48;
  border-color: #182d48;
  outline: none;
  color: #fff;
}

.pagination-products > li > a,
.pagination-products > li > span {
  color: #182d48;
}

.pagination-products > li:first-child > a,
.pagination-products > li:first-child > span,
.pagination-products > li:last-child > a,
.pagination-products > li:last-child > span {
  border-radius: 0.25rem;
}

.filter {
  display: none;
}

.listing-dt-img img {
  height: 360px;
  object-fit: cover;
  border-radius: 5px;
}

.product-detalis-owl .product-aside img {
  width: 46px !important;
  height: 80px;
  object-fit: contain;
  margin: 0 auto;
}

.product-detalis-owl .owl-prev span {
  display: none;
  font-size: 20px;
}

.product-detalis-owl .owl-next span {
  display: block;
  font-size: 28px;
  position: absolute;
  top: 12px;
  line-height: 70px;
}

.product-detalis-owl .owl-nav {
  position: static !important;
  top: 0;
}

.product-detalis-owl .owl-prev {
  position: absolute;
  left: 0;
}

.product-detalis-owl .owl-next {
  position: absolute;
  right: 0;
}

.product-detalis-silder {
  margin-top: 70px;
}

.product-aside {
  border: 1px solid #eee;
  height: 110px;
  width: 100px;
  display: flex;
  align-items: center;
}

.add-product-price h5 {
  /* line-height: 72px; */
  font-size: 25px;
}

.tab-product-box.active {
  background: #66cc8d;
  border-radius: 3px;
  width: 130px;
  padding: 8px 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: #000;
  border: 0;
}

.tab-product-box span {
  color: #000;
  white-space: normal;
  word-break: break-all;
}

.tab-product-box.active span {
  color: #fff;
}

.tab-product-box.active p {
  color: #fff;
}

.review-star {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infomation-part h3 {
  margin-top: 0 !important;
}

.tab-product-box {
  background: transparent;
  border-radius: 3px;
  width: 130px;
  padding: 8px 13px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 0;
  color: #fff;
  border: 1px solid #cfcfcf;
}

.tab-product-box p {
  font-size: 16px;
  color: #182d48;
}

.add-cart {
  border: 0;
  background: #66cc8d;
  color: #fff;
  padding: 14px 30px;
  margin: 30px 0;
}

.enter-pin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-bottom: 20px;
}

.enter-pin input {
  height: 44px !important;
  border: 1px solid #cfcfcf;
  font-size: 12px;
  color: #cfcfcf;
  border-radius: 0;
  width: 100%;
  margin-right: 20px;
}

.enter-pin a {
  color: #182d48;
  font-size: 12px;
  margin-left: 20px;
}

.enter-pin i {
  font-size: 12px;
}

.soical-part li {
  list-style: none;
  border-radius: 4px;
  margin-right: 10px;
}

#searchText {
  position: absolute;
  right: 120px;
  height: 50px;
  border: 1px solid #eee;
  font-size: 14px;
  padding-left: 10px;
  top: 55px;
  z-index: 999999999;
  width: 250px;
  display: flex;
  align-items: center;
}

.soical-part li a {
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  width: 160px;
  display: block;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.orange {
  background: #e55437;
}

.sky-blue {
  background: #46a5dd;
}

.blue {
  background: #4060aa;
}

.soical-part ul {
  display: flex;
}

/* .product-img-static {
  padding: 20px 0;
  z-index: -9;
} */

.infomation-part h3 {
  font-weight: 600;
  font-size: 18px;
  color: #182d48;
}

.background-info {
  background-color: #edfaf2;
  padding: 70px 0;
}

.infomation-part {
  margin-bottom: 30px;
}

.owl-carousel.related-product-list.owl-loaded.owl-drag .wapper-product {
  padding-bottom: 30px;
  width: 95%;
}

/* .product.related-list img {
  width: 70px !important;
} */

/* .react-dropdown-select.form-control.css-wmw4vi-ReactDropdownSelect.e1gzf2xs0 {
  width: 220px;
} */

.new-height {
  position: relative;
}

.view-details {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 20px;
}

.css-148o527-ItemComponent {
  color: #333;
}

.owl-carousel.related-product-list .owl-nav {
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  justify-content: center;
  left: 0;
}

.owl-carousel.related-product-list .owl-next {
  position: absolute;
  right: 0;
  bottom: 0;
}

.owl-carousel.related-product-list .owl-prev::before {
  content: "";
  background: url("/assets/img/pre.png") no-repeat;
  width: 35px;
  height: 66px;
  position: absolute;
  background-position: center;
  border: 1px solid #eee;
  margin: 0px;
  border-radius: 2px;
  background-color: #fff;
  left: -10px;
}

.owl-carousel.related-product-list .owl-next::after {
  content: "";
  background: url("/assets/img/next.png") no-repeat;
  background-color: #66cc8d;
  width: 35px;
  height: 66px;
  position: absolute;
  background-position: center;
  border-radius: 2px;
  right: 0;
}

.close-btn a strong {
  color: red;
  font-size: 16px;
}

.close-btn i {
  color: red;
  font-size: 18px;
}

.available-btn a strong {
  color: #66cc8d;
  font-size: 16px;
}

.available-btn i {
  color: #66cc8d;
  font-size: 18px;
}

.custom-pd {
  padding: 50px 0;
}

/*  */

.nav-item.nav-link.active {
  /* border-bottom: 5px solid #182d48;
  padding-bottom: 5px !important; */
  display: block;
  font-weight: 600;
}

.main-service {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .owl-prev::before {
  margin: 0 -42px !important;
} */

.navbar-light .navbar-toggler {
  border-color: 0 !important;
}

.full-part {
  width: 100%;
  float: left;
}

.side-left:first-child {
  margin-right: 28px;
}

.side-left {
  float: left;
  width: 50%;
  width: calc(50% - 14px);
}

.popup-img.text-left p {
  margin-bottom: 10px !important;
}

.continue a {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
}

.input-group.mb- {
  margin-top: 20px;
}

.continue p span {
  font-weight: bold;
  margin-left: 10px;
}

.continue p span:hover {
  cursor: pointer;
}

.popup-img input {
  border-radius: 0;
}

/* .popup-img h2 {
  font-weight: 600;
} */

.button-custom {
  color: #fff !important;
  line-height: 54px;
  background: #182d48;
  height: 55px;
  text-align: center;
  margin-top: 30px;
  width: 100%;
  display: block;
  border: 0;
  font-size: 16px;
}

.custom-disable {
  background: #d8d8d8;
}

.custom-disable:hover {
  cursor: no-drop;
}

.nav-item.nav-link.active {
  color: #182d48 !important;
  font-weight: 600;
}

.button-custom {
  color: #fff !important;
  line-height: 54px;
  background: #182d48;
  height: 55px;
  text-align: center;
  margin-top: 30px;
  width: 100%;
  display: block;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.custom-disable {
  background: #d8d8d8;
}

.custom-disable:hover {
  cursor: no-drop;
}

.product-conetnt p {
  color: #fff;
}

.form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: inherit !important;
  outline: 0;
  box-shadow: inherit !important;
}

.input-group-append button:focus {
  border: 1px solid #182d48;
}

.input-group-append {
  /* margin-left: -1px; */
  cursor: pointer;
}

.owl-carousel.carousel.slide.carousel-fade.carousel-inner.owl-loaded.owl-drag
  .owl-dot {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: rgba(142, 142, 142, 0.5);
  margin: 5px;
}

.owl-carousel.carousel.slide.carousel-fade.carousel-inner.owl-loaded.owl-drag
  .owl-dot.active {
  background-color: #182d48;
}

.owl-dots {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 20px;
  text-align: center;
}

.tab-content {
  border: 0 !important;
  margin-top: 188px !important;
}

.down-section.classPad .details {
  margin: 0;
  padding: 0;
}

.down-section.classPad {
  padding: 15px 15px;
  border: 1px solid #e5e5e5;
  border-top: 0px;
  border-radius: 0 0 15px 15px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #182d48 !important;
  font-weight: 16px;
  font-weight: 600;
}

/* .custom-form-field select{
  height: 60px;
  border-radius: 0px;
  border: 1px solid #182d48;
  color: #bdbdbd;
  font-size: 15px;
} */

.logout.text-center {
  padding: 100px;
}

.error-wrapper {
  position: absolute;
  bottom: -42px;
  left: 0;
}

.error-wrapper p {
  color: red;
  font-size: 12px;
}

.test-box-popular h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.error-wrapper-pincode {
  position: absolute;
}

.error-wrapper-pincode p {
  color: rgb(139, 108, 108);
  font-size: 12px;
}

.react-dropdown-select.form-control.css-wmw4vi-ReactDropdownSelect.e1gzf2xs0 {
  height: 60px;
  border-radius: 0;
  border: 1px solid #182d48;
  color: #bdbdbd;
  font-size: 17px !important;
}

.react-dropdown-select.form-control.css-5xyd1j-ReactDropdownSelect.e1gzf2xs0 {
  height: 60px;
  border-radius: 0px;
  border: 1px solid #182d48;
  color: #bdbdbd;
  font-size: 17px !important;
}

.main-edit {
  width: 80%;
  margin-bottom: 25px;
}

.product-details {
  width: 100%;
}

.toasts-container {
  height: 100px !important;
}

.add-product img {
  width: 150px;
}

/* .top-product.height-product {
  height: 50vh;
} */

/* 10/02/2020 */

.Our-p p {
  font-size: 17px;
  color: #182d48;
  letter-spacing: 0.44px;
  text-align: center;
  line-height: 28px;
}

.address-part {
  background-color: #edfaf2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  padding: 0;
  margin-bottom: 20px;
}

.address-part p {
  margin-bottom: 0;
}

.Store-Details {
  margin: 0 auto;
  margin-top: 50px;
}

/* .product-name {
  min-height: 70px;
} */

.Store-Details a {
  background: #182d48;
  border: 1px solid #182d48;
  color: #fff;
  padding: 14px 32px;
  display: block;
  font-size: 16px;
}

/* .wapper-product.Featured-Products img {
  width: 50px;
} */

.all-product a {
  background: #182d48;
  color: #fff !important;
  font-weight: 500;
  padding: 13px 40px;
}

.all-product {
  margin: 0 auto;
  margin-top: 40px;
}

.product-page-change .nav-link {
  padding: 0 !important;
}

/* .product-page-change .nav-item {
  background-color: #fff;
} */

.product-page-change .nav-link {
  background-color: #edfaf2;
  padding: 20px 30px !important;
  color: #000;
  border-radius: 0;
}

/* .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: #edfaf2;
} */

.rateing-list ul,
.rateing-list li {
  list-style: none;
  padding-left: 0;
  display: inline-block;
}

.rateing-list li i {
  color: #ffb93f;
  font-size: 17px;
}

.rateing-list a {
  color: #66cc8d;
  margin-left: 5px;
}

.brand-name.Reviews-c {
  display: flex;
  justify-content: space-between;
}

.top-brands-owl .wapper {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eee;
  max-width: 100%;
  width: 100%;
  height: 190px;
}

.top-brands-owl .wapper img {
  width: 100%;
  object-fit: cover;
  height: 200px;
  object-position: center;
}

.review-line {
  border-bottom: 2px solid #eee;
  display: block;
}

.review-line .nav-item.active {
  display: block;
}

.review-line .nav-link {
  color: #000 !important;
}

/* 
.service-aside .new-height {
  height: 200px;
} */

/* .review-line .nav-item.active {
  padding-bottom: 20px;
  border-bottom: 2px solid #182d48;
} */

.rateing-list {
  color: #66cc8d !important;
  font-size: 18px;
}

.resend {
  color: #66cc8d !important;
}

.resend :hover {
  color: #66cc8d !important;
}

.point li {
  background: #182d48;
  display: initial;
  padding: 3px 6px;
  color: #fff;
  border-radius: 3px;
}

.point {
  display: flex;
  align-items: center;
}

.main-time {
  text-align: center;
}

.countdown {
  font-size: 24px;
  color: #000;
  font-weight: 500;
}

.main-time h4 {
  color: #182d48;
  font-weight: 600;
}

.container-calendar {
  background: #ffffff;
  padding: 15px;
  max-width: 475px;
  margin: 0 auto;
  overflow: auto;
}

.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #182d48;
  color: #fff;
  border: 1px solid #182d48;
  border-radius: 4px;
  padding: 5px 10px;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}

.table-calendar td,
.table-calendar th {
  padding: 5px;
  border: 1px solid #e2e2e2;
  text-align: center;
  vertical-align: top;
}

.date-picker.selected {
  font-weight: bold;
  outline: 1px dashed #182d48;
}

.date-picker.selected span {
  border-bottom: 2px solid currentColor;
}

/* sunday */

.date-picker:nth-child(1) {
  color: red;
}

/* friday */

.date-picker:nth-child(6) {
  color: green;
}

#monthAndYear {
  text-align: center;
  margin-top: 0;
}

.button-container-calendar {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden;
  clear: both;
}

#previous {
  float: left;
}

#next {
  float: right;
}

.footer-container-calendar {
  margin-top: 0;
  padding: 20px 0;
  text-align: center;
}

.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.popup-img.update-pd {
  /* margin: 110px 0; */
  padding: 160px 20px;
}

.a-cart {
  display: flex;
}

.a-cart img {
  object-fit: contain;
  position: relative;
  top: 0px;
  left: 11px;
}

.cart-hide {
  position: relative;
  left: -6px;
  margin: 0px;
  opacity: 0;
  width: 0px;
}

.modal-dialog.popup-main {
  max-width: 1200px;
}

.button-custom {
  margin-top: 40px;
}

.new-user b:hover {
  cursor: pointer;
}

.second-part:hover {
  cursor: pointer;
}

.navbar.navbar-expand-lg.bottom-pd {
  padding-top: 0;
}

.green-like .fa.fa-heart {
  position: static;
  color: #66cc8d;
}

.green-like .fa.fa-heart:hover {
  cursor: pointer;
}

.sort-sarech {
  background-color: #182d48 !important;
  width: 180px;
  height: 50px;
  border: 0;
}

.category-title h4 {
  font-size: 20px;
  font-family: RNSSanz-bold !important;
}

.Store-Details a:hover {
  color: #fff !important;
}

.modal-message {
  width: 500px;
}

.modal-message .col-lg-12 .text-center {
  padding: 40px 15px;
}

.admin-nav .nav .nav-item .nav-link {
  display: block;
  padding: 0.75rem 1rem !important;
  color: #fff;
  text-decoration: none;
  background: transparent;
}

.admin-nav .nav .nav-item .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #73818f;
  text-align: center;
}

.app-body .sidebar {
  width: 230px !important;
  position: inherit !important;
}

.main {
  margin-left: 0 !important;
}

.app-body .sidebar .nav,
.app-body .sidebar .sidebar-nav {
  width: 230px !important;
}

.app-footer {
  margin-left: 230px !important;
}

.sidebar-minimized .app-body .sidebar .nav-item,
.sidebar-minimized .app-body .sidebar .nav,
.sidebar-minimized .app-body .sidebar .sidebar-nav {
  width: 40px !important;
  overflow: auto !important;
}

.app-body .sidebar .nav-link:hover {
  color: #fff;
  background: #182d48;
}

.app-header {
  top: 0 !important;
}

.signup-page {
  width: 100%;
  float: left;
}

.signup-bg-image {
  background-color: #e4e5e6;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 75px 0px;
  background-size: cover;
}

.signup-content {
  color: #fff;
  padding-top: 65px;
}

.btn-date {
  background-color: #66cc8d;
  color: #fff;
  padding: 10px 20px;
  border: 0;
  font-size: 16px;
  margin-bottom: 30px;
}

.react-datepicker__input-container input {
  width: 60%;
}

.react-datepicker-popper {
  position: absolute !important;
  will-change: transform !important;
  top: -17px !important;
  width: 100% !important;
  left: -1% !important;
  transform: translate3d(15px, 137px, 0px) !important;
}

.react-datepicker__input-container input,
.react-datepicker__input-container input::placeholder {
  background-color: #66cc8d;
  color: #fff !important;
  padding: 10px 20px;
  border: 0;
  font-size: 16px;
  margin-bottom: 30px;
}

.fa.fa-calendar-o.mr-cal {
  margin-left: 10px;
}

.signup-content h6 {
  font-family: Proxima-Nova-Regular;
  font-size: 18px;
  color: #ffffff;
}

.signup-content h4 {
  font-family: Proxima-Nova-Extra-Bold;
  font-size: 36px;
  color: #ffffff;
  line-height: 46px;
  margin-bottom: 0px;
}

.signup-content p {
  font-family: Proxima-Nova-Regular;
  font-size: 18px;
  color: #ffffff;
}

.signup-stats {
  width: 50%;
  margin-top: 50px;
}

.signup-stats h5 {
  font-family: Proxima-Nova-Bold;
  font-size: 20px;
  color: #78ffd1;
  letter-spacing: -0.4px;
  margin-bottom: 0px;
}

.signup-stats small {
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: -0.3px;
}

.signup-box {
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 57px 50px;
  width: 480px;
  float: right;
}

.signup-box h5 {
  font-family: Proxima-Nova-Bold !important;
  font-size: 24px;
  color: #182d48;
}

.signup-form input {
  background: #ffffff;
  border: 1px solid #b0b0b0;
  border-radius: 6px;
  padding: 8px;
  font-size: 14px;
  color: #b0b0b0;
  line-height: 20px;
  padding-top: 9px;
  margin-bottom: 0px;
  height: auto;
}

.signup-form .custom-checkbox {
  opacity: 0.8 !important;
  font-family: Proxima-Nova-Regular !important;
  font-size: 14px !important;
  color: #000000 !important;
  line-height: 20px !important;
}

.signup-form .icon-in-field {
  position: absolute;
  top: 13px;
  left: -30px;
  height: 14px;
}

.signup-form input::placeholder {
  opacity: 0.3;
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
}

.signup-form label {
  opacity: 0.8;
  font-family: Proxima-Nova-Regular;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  font-weight: normal;
}

.signup-form .password-icon {
  height: 20px;
  top: 7px;
}

.signup-form .eye-icon {
  right: 0px;
  transition: all 0s ease !important;
}

.signup-form .eye-icon {
  right: 13px !important;
  left: auto;
  height: 14px;
}

.signup-form ul {
  list-style: none;
  padding-left: 0px;
}

.h-100vh {
  height: 100vh;
}

.err-class {
  background: #eef6fc;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  color: #bf0404;
  position: relative;
}

.success-class {
  background: #eef6fc;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  color: #bf0404;
  position: relative;
}

p.success-class::before,
p.err-class::before {
  content: "";
  background: #003366;
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

p.success-class span,
p.err-class span {
  color: #182d48 !important;
  border-bottom-color: #182d48 !important;
}

.help-block {
  color: red !important;
  font-size: 13px;
}

.green-btn img {
  height: 16px;
  position: relative;
  top: -2px;
  padding-right: 5px;
}

.green-btn {
  background: #78ffd1 !important;
  border-radius: 22.5px !important;
  text-transform: uppercase !important;
  font-family: Proxima-Nova-Bold !important;
  font-size: 14px !important;
  color: #013469 !important;
  white-space: nowrap !important;
  padding: 8px 16px !important;
  padding-top: 10px !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: unset !important;
}

.btn-common-img {
  background: #00b4f3;
  border-radius: 6px;
  width: 120px;
  color: white;
  height: 40px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
}

.btn-wd {
  width: 135px;
}

.btn-common-img img {
  margin-right: 5px;
}

.btn-common-img:hover {
  color: #fff;
  text-decoration: none;
}

.forgot-password {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: #013469;
  float: right;
  margin-top: 10px;
  white-space: nowrap;
}

.image-format {
  max-height: 300px;
  max-width: 300px;
}

.pagination-product {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.pagination-product > .active > a {
  background-color: #182d48;
  border-color: #182d48;
  color: #fff;
}

.pagination-product > li > a {
  border: 1px solid #e4e4e4;
  padding: 6px 12px;
  outline: none;
  cursor: pointer;
  border-radius: unset !important;
}

.pagination-product > li:first-child > a {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.pagination-product > li:last-child > a {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.pagination-product > .active > a,
.pagination-product > .active > span,
.pagination-product > .active > a:hover,
.pagination-product > .active > span:hover,
.pagination-product > .active > a:focus,
.pagination-product > .active > span:focus {
  background-color: #182d48;
  border-color: #182d48;
  outline: none;
  color: #fff;
}

.pagination-product > li > a,
.pagination-product > li > span {
  color: #182d48;
}

.pagination-product > li:first-child > a,
.pagination-product > li:first-child > span,
.pagination-product > li:last-child > a,
.pagination-product > li:last-child > span {
  border-radius: 0.25rem;
}

.upload-file-admin-btn {
  background: #182d48 !important;
  color: #fff !important;
  border-radius: 36px !important;
  border: 0px !important;
  height: 40px;
  width: 40px;
  float: right;
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.variant-modal {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}

.variant-modal h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: #182d48;
  letter-spacing: -0.4px;
  line-height: 40px;
}

.variant-modal ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.variant-modal ul li {
  float: right;
  display: inherit;
  font-size: 16px;
  color: #182d48;
  letter-spacing: -0.27px;
  cursor: pointer;
}

.variant-modal ul li button {
  background: #182d48;
  color: white;
  border-radius: 28px;
  padding: 5px 15px;
  border: 0px;
  margin-left: 10px;
  height: 34px;
}

.variant-modal ul li span img {
  position: relative;
  top: -1.5px;
}

.variant-modal {
  display: block;
}

span.addVariant-btn {
  width: 32px;
  background: transparent;
  height: 32px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-bottom: 5px;
}

.rotate-imge {
  transform: rotate(45deg);
}

.mandatory_red_class {
  color: red;
  float: right;
  margin-left: 2px;
  font-size: 15px;
}

.react-switch-bg {
  background: #182d48 !important;
  color: white !important;
}

.fileContainer .uploadIcon {
  width: 35px !important;
  height: 30px !important;
}

.fileContainer .chooseFileButton {
  background: #182d48 !important;
  color: white !important;
}

.no-data {
  margin-left: 15px;
}

.fileContainer {
  background: #fff;
  box-shadow: 3px 3px 5px 5px rgba(0, 0, 0, 0.05) !important;
  position: relative;
  border-radius: 10px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  transition: all 0.3s ease-in;
}

.fileContainer .uploadPictureContainer {
  max-width: 14% !important;
  max-height: inherit !important;
  margin: 1%;
  padding: 0px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
}

.fileContainer .deleteImage {
  position: absolute;
  top: -15px !important;
  right: -15px !important;
  color: #182d48 !important;
  background: #f3f3f3 !important;
  border-radius: 50%;
  border: 1px solid #d0dbe4;
  text-align: center;
  cursor: pointer;
  font-size: 15px !important;
  font-weight: bold;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.fileContainer .uploadPictureContainer {
  width: 15% !important;
  margin: 1% !important;
  padding: 0 !important;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
}

input[type="radio"] {
  margin-right: 130px !important;
  width: 25px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.popup-table.modal-content {
  width: 800px !important;
}

.service-aside h3 {
  font-size: 18px;
  color: #182d48;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  overflow: hidden;
  font-family: RNSSanz-medium !important;
}

.sp-plus.fff:hover {
  cursor: pointer;
}

/* .our-scrvice-height {
  height: 171px;
} */

.searchInput {
  -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05) !important;
  -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05) !important;
  border: 0 !important;
  height: 50px !important;
  padding: 15px !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #23282c !important;
  position: relative !important;
  width: 30% !important;
  max-width: none;
  margin-left: 20px !important;
  margin-top: 20px !important;
  display: inline-block !important;
}

.focusclass input[type="search"]:focus {
  outline: 0px;
}

/* .text-center.img-product img {
  width: 230px;
  height: 250px;
  object-fit: contain;
} */

.text-center.img-product img {
  max-width: 230px;
  height: 250px;
  object-fit: contain;
  width: 100%;
}

.modal-head {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-head h3 {
  font-family: Proxima-Nova-Bold;
  font-size: 24px;
  color: #182d48;
  letter-spacing: -0.4px;
  line-height: 40px;
  padding: 10px 30px;
}

.modal-head ul {
  margin: 0px;
  padding: 10px 30px;
  list-style: none;
  float: right;
}

.modal-head ul li {
  display: inline-block;
  font-size: 16px;
  color: #182d48;
  letter-spacing: -0.27px;
  cursor: pointer;
}

.modal-head ul li button {
  background: #182d48;
  color: white;
  border-radius: 28px;
  padding: 5px 15px;
  border: 0px;
  margin-left: 10px;
  height: 34px;
}

.close-top {
  background: #f3f3f3;
  color: #182d48;
  border-radius: 40px;
  padding: 7px 16px;
  border: 0px;
  margin-left: 10px;
  height: 34px;
}

.form-modal-div table a {
  color: #182d48;
}

.form-modal-div .table thead th {
  font-family: Proxima-Nova-Bold;
  font-weight: 700;
  border-top: 0px;
  font-size: 18px;
  color: #182d48;
  letter-spacing: -0.23px;
}

.form-modal-div .table td {
  font-size: 15px;
  font-weight: normal;
  border-top: 0px;
  font-family: Proxima-Nova-Bold;
}

.top-brands-owl img {
  width: 250px;
  object-fit: contain;
}

.text-title {
  position: absolute;
  left: 20px;
  bottom: 0;
  color: #fff;
  font-size: 18px;
}

.text-title h4 {
  font-size: 28px;
  font-weight: 700;
}

.top-brands-owl.icon-new .owl-nav {
  top: 60px;
}

.top-brands-owl.icon-new .owl-prev::before {
  height: 36px;
}

.top-brands-owl .owl-next::after {
  height: 36px;
}

.sticky-top-custom {
  position: fixed !important;
  z-index: 99999;
  top: 0;
  width: 100%;
  padding-top: 10px !important;
  left: 0;
  background-color: #fff;
  box-shadow: -4px 7px 35px -8px rgb(232, 230, 230);
}

.form-modal-div {
  width: 100%;
  padding: 0 35px;
  max-height: 500px;
  overflow: scroll;
}

.custom-star span {
  font-size: 18px;
}

.phone-fix-card {
  display: none;
}

.btn-right {
  float: right;
}

.custom-star {
  margin-top: 20px;
}

.new-barnd {
  margin-bottom: 25px;
}

.rating-btn {
  border: 0;
  padding: 13px;
  box-shadow: inherit !important;
}

.shop-concern-upt {
  padding: 75px 0px;
}

.apppy-btn {
  display: none;
}

/* .infomation-part.review-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
} */

.form-modal-div label {
  font-family: Proxima-Nova-Bold;
  font-size: 14px;
  color: #182d48;
  letter-spacing: -0.23px;
  margin-bottom: 0px;
}

.form-modal-div label span {
  color: #ff0000;
}

/* .align-items-center.view-details {
  padding: 30px 40px;
} */

.modal-list {
  width: 100%;
  display: flex;
  align-items: center;
}

.modal-list ul {
  margin: 0px;
  padding: 5px 10px;
  list-style: none;
}

.modal-list ul li {
  display: inline-grid;
  font-size: 16px;
  color: #182d48;
  letter-spacing: -0.27px;
  cursor: pointer;
  padding: 5px 10px;
}

.modal-list ul li h5 {
  font-weight: 700;
  font-size: 18px;
}

.hide-web.nav-link {
  display: none;
}

.review-line .nav-item {
  margin-bottom: 20px;
}

.modal-list ul li p {
  font-size: 16px;
  font-weight: normal;
  border-top: 0px;
  font-family: Proxima-Nova-Bold;
}

.side-heading {
  border-radius: 10px;
  overflow: hidden;
}

.site {
  color: #182d48;
  font-weight: 500;
}

.rating-stars span.fa.fa-star.fa-2x,
.rating-stars span.fa.fa-star-o.fa-2x {
  color: #ffb93f;
  font-size: 17px;
}

.my-order-flex {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.producrt-list {
  display: flex;
  align-items: center;
}

.pagination-products {
  position: relative;
  right: 30px;
}

.close-btn-new {
  position: absolute;
  right: 40px;
  top: 20px;
  font-size: 32px;
  z-index: 9999;
  cursor: pointer;
}

.close-btn-new {
  display: none;
}

.product-name {
  width: 100%;
  cursor: pointer;
}

.filter.fix-filter button {
  border-radius: 0 !important;
}

.multi-list button {
  border: 0;
  padding: 0;
  background: transparent;
  color: #66cc8d;
  font-size: 14px;
  font-weight: 700;
}

.copy-right-text {
  text-align: center !important;
  display: flex;
  justify-content: center;
  position: relative;
  top: 25px;
  font-size: 14px;
  border-top: 1px solid #2f2f2f;
  padding-top: 20px;
}

.copy-right-text:hover {
  color: #fff !important;
}

.first-part {
  width: 86%;
}

.btn-right button {
  margin-right: 15px;
}

.service-text-width {
  display: flex;
  justify-content: space-between;
}

/* .infomation-part{
  display: flex;
    align-items: center;
    justify-content: space-between;
} */

.side-admin.nav-link {
  display: none;
}

.new-price p {
  font-size: 16px;
  color: #000 !important;
  font-weight: normal !important;
  margin-left: 0;
  margin-bottom: 0;
}

/* .rate {
  position: relative;
  margin-top: 15px;
}
.rate {
  display: flex;
  justify-content: space-between;
} */

.discount-part {
  color: #e10000f0 !important;
  text-decoration: line-through;
  font-size: 14px !important;
}

.discount-part {
  display: flex;
  align-items: center;
}

/*  */

.cancel {
  color: red;
  margin-bottom: 0;
}

.more-btn.size-big a {
  font-size: 20px;
}

/* .more-btn.size-big {
  position: relative;
  height: auto;
  width: 150px;
  float: right;
  text-align: right;
} */

/* .date-time {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: 700;
} */

.footer-link a:hover {
  color: #fff !important;
}

/*  */

.title-name-list h3 {
  font-size: 18px;
  color: #182d48;
  letter-spacing: 0;
}

.title-name-list {
  padding-left: 20px;
}

.more-btn a {
  color: #66cc8d;
}

.category-sort .form-control.css-wmw4vi-ReactDropdownSelect.e1gzf2xs0 {
  height: 45px;
  border-radius: 5px;
  border: 1px solid #182d48;
  color: #bdbdbd;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  width: 150px;
  padding: 10px;
  position: relative;
  margin-bottom: 0px;
}

.category-sort .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 5px;
  top: 11px;
}

.tab-flied .tab-content {
  margin-top: 60px !important;
}

.category-sort
  .react-dropdown-select-dropdown-handle.css-1aarvou-DropdownHandleComponent.e1vudypg0 {
  top: 19px;
}

.sec-mar {
  margin-top: 140px;
}
.slider-home {
  margin-top: -22px !important;
}
div#v-pills-tabContent {
  margin-top: 200px !important;
  height: calc(100vh - 200px);
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-left: 50px;
}

.tb-content-custom.add-address .main-edit {
  width: 100% !important;
}

.setup-content .edit-part {
  height: 170px;
}

.tab-content {
  width: 100%;
}

/* .nav-item.nav-link:hover {
  border-bottom: 2px solid #182d48;
} */

.product.fea img {
  /* width: 120px !important; */
  object-fit: contain;
  /* height: 250px; */
}

.product.fea .fa {
  right: 25px;
}

.wapper-product .product.related-list img {
  height: 100%;
  max-width: 160px;
  width: 100%;
}

.product {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 260px;
}

.react-bs-table .table-bordered > tbody > tr > td > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pathlogy-nb::before {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.product-main-div.change-postion {
  position: initial;
}

.rateing-list {
  font-weight: 700;
}

.health-owl .owl-nav {
  display: none !important;
}

.sroll-side {
  /* overflow-y: scroll; */
  max-height: 100%;
  overflow-x: hidden;
  display: block !important;
  /* height: 80vh;
  position: fixed;
  max-width: 320px;
  width: 100%; */
}

/* ss css changed */

.product-head {
  text-align: center;
  width: 100%;
}

.top-produuct2 {
  padding: 0px !important;
  padding-bottom: 50px !important;
}

.service-aside2 {
  border: 2px solid #d2ebff;
}

.category-sort
  .react-dropdown-select-dropdown-handle.css-1aarvou-DropdownHandleComponent.e1vudypg0 {
  top: 9px;
}

.wishlist-head {
  color: #66cc8d !important;
}

.wishlist-head:hover {
  color: #538d69 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #ccc;
  display: none;
}

.new-price p,
.new-price a {
  font-size: 16px;
  color: #000 !important;
  font-weight: normal !important;
  margin-left: 0;
  margin-bottom: 0;
}
.item {
  position: relative !important;
}
.item .plus {
  background: #66cc8d;
  color: #fff;
  width: 24px;
  bottom: 58px;
  right: 27px;
  z-index: 3;
  height: 23px;
  text-align: center;
  line-height: 25px;
  position: absolute;
}
.discount-part {
  color: #e10000f0 !important;
  text-decoration: line-through !important;
  margin-bottom: 0px;
}

.discount-part {
  display: flex;
  align-items: center;
}

/* .product-name h3{
font-size: 22px;
color: #182d48;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
font-family: RNSSanz-bold !important;
} */

.no-scroll-body {
  overflow: hidden !important;
}

.or-text-check {
  margin-bottom: 0px;
}

.main-price-upt-np {
  color: #182d48;
  font-size: 22px;
  margin-top: 10px;
  white-space: nowrap;
}

.multi-list li input {
  margin-right: 10px;
}

.service-aside-int-box {
  height: 120px;
}

@media screen and (max-device-width: 767px) {
  body .shop-concern-upt {
    padding-top: 0px !important;
    padding-bottom: 25px;
  }
  .service-aside-int-box {
    height: 100% !important;
  }
  .remove-move .fa-heart-o {
    top: 1px !important;
    left: -19px !important;
    float: right !important;
    position: absolute !important;
  }
  .add-product-list .price {
    position: initial !important;
  }
  .second-nav-wrp .nav-link:first-child {
    padding-left: 10px !important;
  }
}

.pym-mth-cls .form-check.form-check-inline input[type="radio"] {
  margin-right: 5px !important;
  width: 25px !important;
}

.apppy-btn.apply-filter-btn {
  width: 100%;
  position: fixed;
  bottom: 0px;
}

.our-scrvice-height .view-details a {
  position: initial !important;
  z-index: auto !important;
}

.tab-product-price .tab-product-box {
  height: 80px !important;
  display: flex;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
}

.tab-product-price .tab-product-box.active {
  margin-right: 0px !important;
}

.tab-product-box p {
  margin-bottom: 0px;
}

.service-aside h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.service-aside-int-box {
  min-height: 140px !important;
}

.tab-product-price {
  display: flex;
  overflow-x: scroll;
}

.tab-product-price .tab-product-box {
  margin-bottom: 30px !important;
  height: auto !important;
}

.tab-product-price .tab-product-box:first-child {
  margin-left: 0px !important;
}

.owl-carousel.health-owl .owl-nav.disabled,
.owl-carousel.health-owl .owl-dots.disabled {
  display: none !important;
}

.owl-carousel.people-owl .owl-nav.disabled,
.owl-carousel.people-owl .owl-dots.disabled {
  display: none !important;
}

.people-owl .owl-nav {
  display: none !important;
}

.btn.btn-browse-hc {
  background: #182d48;
  color: #fff;
  font-weight: 500;
  border-radius: 0px;
  padding: 10px 20px;
}

.btn.btn-browse-hc:hover {
  background: #66cc8d;
  color: #182d48 !important;
}

.covid-desc {
  background: #182d48;
  padding: 25px;
  text-align: center;
}

.covid-desc h4 {
  color: #fff;
  font-size: 18px;
  font-family: RNSSanz-medium !important;
  font-weight: 600;
  margin-bottom: 0px;
}

.outofstock {
  margin-bottom: 0px !important;
  position: absolute;
  right: 0;
  top: 60%;
  font-size: 14px !important;
  background: #182d48;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 3px;
  left: 0;
  width: 125px;
  text-align: center;
  margin: 0 auto;
  z-index: 99;
}

.ofs-blur-box {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
}

.outofstock-wishlist {
  margin-bottom: 0px !important;
  position: absolute;
  right: 0;
  top: 40%;
  font-size: 14px !important;
  background: #182d48;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 3px;
  left: 0;
  width: 125px;
  text-align: center;
  margin: 0 auto;
  z-index: 99;
}

.z-index-none a:nth-child(1) {
  z-index: inherit !important;
}

.phone-fix-card.d-block.fix-page ul li:first-child {
  position: relative !important;
}

/* li.add-to-cart a {
  background: #182d48;
  color: #fff !important;
  margin-left: 15px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.add-to-cart a {
  background: #69cd90;
  border: 1px solid #69cd90;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 3px;
  color: #fff !important;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: capitalize;
}

/*  */

.top-text {
  display: flex;
  justify-content: space-between;
  background: #182d48;
  padding: 10px 15px;
  color: #fff;
  position: relative;
  display: none;
}

.top-text.removal_top {
  display: none;
  transition: 0.5s all ease;
}

.top-text h6 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 12px;
  padding: 5px 0px;
}

.top-text span {
  color: #8bc64a;
  cursor: pointer;
}

.phone-change img {
  filter: brightness(0) invert(1);
}

.phone-change {
  position: absolute;
  right: 15px;
}

.phone-change a {
  color: #fff;
  font-weight: 600;
}

.input-group-text.icon-bg {
  background: #182d48;
  color: #fff;
  padding: 0px 20px;
  font-size: 20px;
  height: 42px;
  position: relative;
  top: -1px;
  border-radius: 10px;
  border: 0;
}

/* .input-group.input-width {
    min-width: 700px;
      height: 50px;
      width: 100%;
  } */

.input-group.input-width input {
  height: 40px;
  font-size: 12px;
  /* box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.2); */
  border-radius: 0px;
  border-color: #dfdfdf !important;
  padding-left: 10px;
}

.main-content-shop {
  height: 100%;
  margin-top: 30px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e6e6e6 !important;
}

/*  */

/* .add-to-cart a:hover {
  background: #182d48;
  border: 1px solid #182d48;
  color: #fff !important;
} */

.icon-section i {
  color: #182d48;
}

.icon-section a {
  padding-right: 15px;
  font-weight: 700;
}

i.fa.fa-phone {
  padding-right: 3px;
}

i.fa.fa-whatsapp {
  padding-left: 5px;
}

.phone-nav {
  display: none;
}

/* 
.shop-content {

  min-height: 378px;

} */

.second-part.wishlist_ul {
  align-items: center;
  justify-content: end;
  margin-top: 15px;
}

.header-part.header_align {
  align-items: center;
}

.second-part.wishlist_ul ul {
  margin-bottom: 0;
}

.input-group.input-width {
  min-width: 600px;
  height: 50px;
  width: 100%;
}

.modalCart a {
  display: block;
  max-width: 175px;
  width: 100%;
}

.modalCart {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

/* 
@font-face {
  font-family: RNSSanz-Normal;
  src: url("../fonts/RNSSanz-Normal.ttf");
}

@font-face {
  font-family: RNSSanz-medium;
  src: url("../fonts/RNSSanz-Medium.ttf");
}
@font-face {
  font-family: RNSSanz-bold;
  src: url("../fonts/RNSSanz-Bold.ttf");
}

@font-face {
  font-family: RNSSanz-x-bold;
  src: url("../fonts/RNSSanz-ExtraBold.ttf");
} */

html,
body {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden !important;
  font-weight: 400;
  color: #353535;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  outline: 0;
  margin: 0;
  padding: 0;
  background: none !important;
}

a:hover {
  text-decoration: none;
  color: inherit !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.item:hover {
  cursor: pointer;
}

p {
  font-size: 16px !important;
  color: #303030;
  font-family: RNSSanz-medium !important;
}

a {
  font-family: RNSSanz-medium !important;
}

button:focus {
  outline: none;
  border: none;
  outline: 0;
}

a:hover {
  text-decoration: none !important;
  cursor: pointer;
  transition: all 0s ease;
}

button:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  outline: 0;
}

header {
  width: 100%;
  float: left;
}

section {
  width: 100%;
  overflow: hidden;
}

h3 {
  font-family: RNSSanz-medium !important;
}

h2 {
  font-family: RNSSanz-x-bold !important;
}

.overflow-hide {
  overflow: initial;
  margin-top: 30px;
}

.second-part.wishlist_ul ul li img {
  max-width: 14px;
  width: 100%;
  object-fit: contain;
}

/* css start */

.path-banner {
  background: url("/assets/img/path-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 100px;
  background-position: center;
  margin-top: 180px;
}

.popular-tests-tab .tab-content {
  margin-top: 0px !important;
}

.path-stats ul {
  display: flex;
  list-style: none;
  align-items: baseline;
  padding-left: 0px;
  margin-left: -25px;
  margin-top: 25px;
}

.path-banner-content h2 {
  font-size: 36px;
  color: #182d48;
  font-family: RNSSanz-bold !important;
  margin-bottom: 15px;
  line-height: 100%;
}

.path-banner-content p {
  color: #182d48;
  font-size: 16px !important;
}

.path-stat-circle {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 0 auto;
  border-radius: 150px;
  border: 2px solid #182d48;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.path-stat-wrp:hover .path-stat-circle {
  background: #182d48;
  transition: all 0.45s ease;
  -webkit-transition: all 0.5s ease;
}

.path-stat-wrp:hover img {
  background: #182d48;
  transition: all 0.4s ease;
  -webkit-transition: all 0.5s ease;
  filter: brightness(0) invert(1);
  background: transparent;
}

.path-stat-circle img {
  height: 50px;
  object-fit: contain;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.path-stat-wrp {
  text-align: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.path-stat-wrp h5 {
  font-size: 14px;
  color: #000;
  line-height: 125%;
  margin-top: 10px;
  font-family: RNSSanz-bold !important;
}

.popular-test {
  padding: 75px 0px;
}

.nw-heading h4 {
  font-size: 32px;
  color: #182d48;
  font-weight: 700;
  font-family: RNSSanz-x-bold !important;
}

.popular-tests-tab {
  padding-top: 30px;
}

.popular-tests-tab ul {
  justify-content: space-between;
}

.popular-tests-tab .nav-pills .nav-link.active,
.popular-tests-tab .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #182d48;
}

.popular-tests-tab .nav-pills .nav-link.active > img,
.popular-tests-tab .nav-pills .show > .nav-link > img {
  filter: brightness(0) invert(1);
}

.popular-tests-tab .nav-link:hover {
  color: #fff !important;
}

.popular-tests-tab .nav-link img {
  height: 35px;
  object-fit: contain;
  margin-right: 5px;
}

.popular-tests-tab .nav-link {
  border: 2px solid #182d48;
  border-radius: 5px;
  color: #182d48;
  padding: 12px 24px;
}

.popular-tests-tab .nav-item:hover .nav-link {
  background: #182d48;
  color: red;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.popular-tests-tab .nav-item:hover img {
  filter: brightness(0) invert(1);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.test-box-popular {
  border: 2px solid #dfdfdf;
  padding: 25px;
  border-radius: 2px;
  height: 100%;
}

.test-box-popular img {
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}

.popular-test-owl {
  /* padding: 35px 0px; */
}

.test-box-popular h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  padding-top: 5px;
}

.price-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-flex h5 {
  font-size: 14px;
  padding-top: 8px;
}

.test-box-popular h5 {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 500;
}

.cut-price {
  color: rgba(225, 0, 0, 0.9411764705882353) !important;
  text-decoration: line-through !important;
  margin-bottom: 0;
}

.test-box-popular a {
  font-size: 16px;
  color: #353535;
  font-weight: 500;
  border-bottom: 2px solid #ccc;
}

.test-box-popular button {
  background: #45bb72;
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
}

.our-associates {
  background: #f3f9ec;
  padding: 75px 0px;
}

.associate-logo-wrp {
  margin-top: 35px;
}

.assocaite-logo {
  background: #fff;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  padding: 15px;
}

.assocaite-logo img {
  height: 200px;
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
}

.all-test {
  padding: 75px 0px;
}

.all-test-wrp {
  padding-top: 35px;
}

.pt-30 {
  padding-top: 30px;
}

.btn.btn-view-all {
  background: #182d48;
  color: #fff;
  padding: 10px 30px;
  border-radius: 0px;
}

.path-search-box {
  max-width: 55%;
  position: relative;
}

.path-search-box input {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #182d48;
}

.path-search-box input:focus {
  box-shadow: unset;
}

.path-search-box button {
  background: #182d48;
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 0px;
}

.path-search-box img {
  height: 20px;
}

@media screen and (min-device-width: 250px) and (max-device-width: 992px) {
  /* responsive css start */
  .path-banner {
    padding: 50px 25px;
  }
  .path-stats ul li {
    display: inline-block;
  }
  .popular-tests-tab .nav-item {
    margin-right: 10px;
    margin-bottom: 0px;
    width: auto !important;
  }
  .popular-tests-tab .nav-link {
    padding: 10px 5px !important;
  }
  .path-search-box {
    max-width: 540px !important;
  }
  .test-box-popular {
    height: auto;
    margin-bottom: 30px;
  }
  .assocaite-logo {
    margin-bottom: 15px;
  }
  .pt-30 {
    padding-top: 0px;
  }
}

.detail-sec-wpr {
  margin-top: 50px;
}

.detail-outer {
  border: 2px solid #8b96a3;
  padding: 14px 45px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 30px;
}

.detail-outer p {
  margin-bottom: 0px;
}

.detail-outer h2 {
  margin-bottom: 0px;
}

.detail-left h2 {
  font-size: 35px;
  color: #45bb72;
  margin-bottom: 5px;
}

.detail-left p {
  font-size: 18px !important;
  margin-bottom: 5px;
  font-size: 36px;
  font-family: "RNS Sanz";
  color: rgba(26, 26, 26, 0.949);
}

.strike-pay {
  font-size: 25px;
  color: rgb(236, 0, 1);
  text-decoration: line-through;
  margin-right: 15px;
}

.actuall-pay {
  font-size: 20px;
  color: rgb(109, 168, 45);
}
.detail-right {
  text-align: center !important;
  display: flex;
  flex-direction: column;
}
.detail-right .btn {
  background: #45bb72;
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
  float: right;
  margin-top: 10px !important;
}

.detail-right .btn:hover {
  color: #212529;
  text-decoration: none;
}

.packages-outer {
  margin-top: 40px;
  background: #45bb721f;
  padding: 15px 25px;
}

.packages-outer h2 {
  font-size: 30px;
  color: rgb(26, 26, 26);
  text-align: left;
}

.packages-outer p {
  font-size: 18px !important;
  color: rgb(26, 26, 26);
}

.catagory-outer {
  margin-top: 40px;
  padding: 15px;
  border-bottom: 2px solid #8b96a3;
  margin-bottom: 20px;
}

.catagory-outer h2 {
  font-size: 30px;
  margin-bottom: 15px;
  color: rgb(26, 26, 26);
}

.catagory-outer h6 {
  font-size: 18px;
  color: rgb(26, 26, 26);
  text-transform: uppercase;
  margin-bottom: 0;
}

.diagnostics-outer {
  background: #f3f9ec;
  text-align: center;
  padding: 16px 10px;
  border: 2px solid #d8d8d8;
  padding-bottom: 0;
}

.diagnostics-outer h1,
.diagnostics-outer h2,
.diagnostics-outer p {
  margin-bottom: 0;
}

.diagnostics-outer h1 {
  font-size: 18px;
  color: rgb(26, 26, 26);
  margin-bottom: 15px;
}

.diagnostics-outer h2 {
  font-size: 25px;
  color: rgb(24, 45, 72);
  margin-bottom: 15px;
}

.avail-p p {
  padding: 15px 0px;
  border-top: 2px solid #e5e8e1;
  margin-bottom: 0;
}

@media screen and (max-width: 992px) {
  .detail-left h2 {
    font-size: 18px;
  }
  .strike-pay {
    font-size: 16px;
  }
  .detail-outer {
    padding: 14px 20px;
  }
  .actuall-pay {
    font-size: 15px;
  }
  .detail-right .btn {
    font-size: 10px;
  }
  .packages-outer h2 {
    font-size: 25px;
  }
  .packages-outer p {
    font-size: 13px !important;
  }
  .catagory-outer {
    margin-top: 15px;
  }
  .catagory-outer h2 {
    font-size: 24px;
  }
  .catagory-outer h6 {
    font-size: 13px;
  }
  .diagnostics-outer {
    margin-bottom: 20px;
  }
}

.popular-tests-tab .nav-link {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 200px;
}

.popular-tests-tab .nav-link.active {
  background: #182d48 !important;
  color: #fff !important;
}

.test-box-popular p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.all-test-wrp .col-lg-4 {
  margin-bottom: 30px;
}

/* landing page css start heare */

.slider-outer {
  float: left;
  width: 100%;
}

.caro-item {
  min-height: 533px;
  background-image: url("/assets/img/banner-image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
}

.carou-text h2 {
  font-size: 50px;
  color: #fff;
  font-style: italic;
  text-transform: uppercase;
  font-family: RNSSanz-bold;
  margin-bottom: 0px;
}

.carou-text p {
  font-size: 20px !important;
  color: #fff;
  margin-bottom: 0;
}

.carou-text {
  max-width: 1440px;
  margin: auto;
  height: 533px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.slider-outer .owl-nav {
  display: none;
}

.slider-outer .owl-dots {
  text-align: center;
  margin-top: 15px;
}

.slider-outer .owl-dots button.owl-dot {
  background: #d5d9de;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  outline: none;
  margin-right: 15px;
}

.slider-outer .owl-dots button.owl-dot.active {
  background-color: #182d48;
}

section.about-mms-sect {
  width: 100%;
  float: left;
  margin-top: 75px;
}

.about-out h3 {
  font-size: 35px;
  color: #182d48;
  font-family: RNSSanz-bold !important;
  position: relative;
  margin-bottom: 25px;
}

.about-out h3::before {
  content: "";
  position: absolute;
  width: 90px;
  bottom: -12px;
  left: 0;
  height: 3px;
  background-color: #182d48;
}

.about-out p {
  font-size: 28px !important;
  color: #182d48;
  line-height: 36px;
  font-family: RNSSanz-medium !important;
}

.about-ul-out {
  display: flex;
  margin-top: 50px;
}

.about-ul-out ul {
  font-size: 18px;
  color: #182d48;
  padding-left: 20px;
}

.about-ul-out ul li {
  margin-bottom: 25px;
  font-family: RNSSanz-medium !important;
}

.about-ul-lft {
  padding-right: 12px;
}

.youg-out {
  position: relative;
  height: 100%;
}

.youg-out img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 9;
}

.youg-out::after {
  content: "";
  position: absolute;
  top: 20px;
  right: -38px;
  z-index: -1;
  background-image: url("/assets/img/dots-dots.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  width: 304px;
  height: 300px;
}

section.mediCall-sec {
  width: 100%;
  float: left;
  margin-top: 60px;
}

.whoWeAre.about-out p:nth-child(2) {
  font-size: 30px !important;
  margin-bottom: 5px;
  font-family: RNSSanz-SemiBold !important;
}

p.neig {
  font-size: 25px !important;
  margin-bottom: 00px;
}

.callForShop {
  width: 100%;
  background-image: url("/assets/img/medicall.png");
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.whoWeAre.about-out {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.callForShop h1 {
  font-size: 50px;
  font-family: "RNSSanz-x-bold";
  color: #182d48;
}

.btn.btn-shop {
  background: #69cd90;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  width: 135px;
  height: 45px;
  line-height: 32px;
}

.about-out.text-center.whatWeDo-head h3 {
  max-width: 450px;
  margin: auto;
  margin-bottom: 25px;
}

.about-out.text-center.whatWeDo-head h3::before {
  left: 180px;
}

.about-out.text-center.whatWeDo-head p {
  font-size: 20px !important;
  max-width: 950px;
  margin: auto;
  line-height: 31px;
}

section.whatWeDo {
  margin-top: 70px;
  width: 100%;
  float: left;
  background: #f8faff;
}

.whatWeDo-outer {
  padding: 65px 0px;
  width: 100%;
  float: left;
}

.tags-outer {
  width: 100%;
  float: left;
  margin-top: 60px;
}

.comn-tag {
  text-align: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 25px;
}

.comn-tag p {
  color: #fff !important;
  font-size: 14px !important;
  margin-bottom: 0;
}

.comn-tag img {
  margin-bottom: 25px;
  max-width: 80%;
  width: 100%;
}

.tag-img {
  height: 120px;
}

.tag1 {
  background-image: url("/assets/img/g1.png");
  height: 290px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.tag2 {
  background-image: url("/assets/img/g2.png");
  height: 290px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.tag3 {
  background-image: url("/assets/img/g3.png");
  height: 290px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.tag4 {
  background-image: url("/assets/img/g4.png");
  height: 290px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.wherwbuy-content img {
  max-width: 1840px;
  margin: auto;
  width: 100%;
}

section.whereBuy {
  width: 100%;
  float: left;
  margin-top: 60px;
}

section.offer-dis {
  width: 100%;
  float: left;
  margin: 60px 0px;
}

section.detail-sec-wpr.pt-5 {
  margin-top: 170px;
}

.offer-dis-inner {
  height: 235px;
  background-image: url("/assets/img/emial-bnr.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  background-position: center;
}

.offer-content h2 {
  font-size: 55px;
  margin-bottom: 0;
  color: #182d48;
  font-family: "RNSSanz-bold";
}

.offer-content p {
  font-size: 25px !important;
  font-family: "RNSSanz-medium";
  margin-bottom: 0px;
  color: #182d48;
}

.eml-inp input {
  width: 100%;
  padding: 6px 11px;
  border: 1px solid #70707026;
  height: 50px;
  border-radius: 6px;
}

.eml-inp input::placeholder {
  color: #182d48;
  font-size: 15px;
}

.eml-inp {
  height: 100%;
  display: flex;
  align-items: center;
}

.eml-inp input:focus {
  outline: none;
}

/* landing page css start heare */

/* landing page  reposive css */

@media screen and (max-width: 1440px) {
  .carou-text {
    max-width: 1280px;
  }
  .offer-dis-inner {
    height: 100%;
    background-image: url("/assets/img/emial-bnr.png");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    background-position: center;
    align-items: center;
    min-height: 300px;
  }
  section.offer-dis {
    margin: 30px 0px;
  }
}

@media screen and (max-width: 1280px) {
  .about-out.text-center.whatWeDo-head p {
    font-size: 24px !important;
    max-width: 1024px;
  }
  .carou-text {
    max-width: 1160px;
    height: 480px;
  }
  .caro-item {
    min-height: 480px;
    background-position: 80%;
  }
  .offer-dis-inner {
    background-position: 100%;
  }
  section.offer-dis {
    margin: 0px 0px;
  }
}

@media screen and (max-width: 1024px) {
  .carou-text {
    max-width: 940px;
    height: 380px;
  }
  .carou-text h2 {
    font-size: 35px;
  }
  .caro-item {
    min-height: 383px;
    background-position: 90%;
  }
  .about-out h3 {
    font-size: 30px;
  }
  .about-out p {
    font-size: 22px !important;
    line-height: 29px;
  }
  .about-ul-out ul li {
    margin-bottom: 15px;
  }
  .about-ul-out {
    margin-top: 28px;
  }
  .whoWeAre.about-out p:nth-child(2) {
    font-size: 25px !important;
  }
  p.neig {
    font-size: 20px !important;
  }
  .callForShop h1 {
    font-size: 45px;
  }
  .callForShop {
    height: 210px;
    padding: 20px;
  }
  .btn.btn-shop {
    font-size: 15px;
    width: 130px;
    height: 40px;
    line-height: 27px;
  }
  .whatWeDo-outer {
    padding: 45px 0px;
  }
  .about-out.text-center.whatWeDo-head p {
    font-size: 19px !important;
    max-width: 850px;
  }
  .tags-outer {
    margin-top: 40px;
  }
  .about-out p {
    font-size: 20px !important;
  }
  .about-ul-out ul {
    font-size: 16px;
  }
  .youg-out::after {
    content: "";
    position: absolute;
    top: 4px;
    right: -21px;
    z-index: -1;
    background-image: url("/assets/img/dots-dots.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    width: 220px;
    height: 226px;
  }
  .offer-dis-inner {
    height: 183px;
    background-position: 100%;
  }
  .eml-inp input {
    height: 45px;
  }
  .offer-content h2 {
    font-size: 48px;
  }
  .offer-content p {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .carou-text {
    max-width: 720px;
    height: 280px;
  }
  .caro-item {
    min-height: 280px;
    background-position: 80%;
  }
  .carou-text h2 {
    font-size: 30px;
  }
  .carou-text p {
    font-size: 18px !important;
  }
  .comn-tag {
    margin-bottom: 10px;
    justify-content: center;
  }
  .about-out.text-center.whatWeDo-head p {
    max-width: 720px;
  }
  .callForShop h1 {
    font-size: 35px;
  }
  .whoWeAre.about-out p:nth-child(2) {
    font-size: 23px !important;
  }
  .about-out p {
    font-size: 16px !important;
  }
  .callForShop {
    background-position: 30%;
  }
  .about-ul-out ul {
    font-size: 14px;
  }
  .about-ul-out ul {
    padding-left: 8px;
  }
  .youg-out::after {
    top: 30px;
    right: -25px;
    z-index: -1;
    width: 253px;
    height: 250px;
  }
  section.offer-dis {
    margin: 40px 0px;
  }
  .offer-dis-inner {
    height: 250px;
    background-position: 100%;
    background-size: cover;
  }
  .offer-content {
    margin-bottom: 15px;
  }
  .eml-inp input {
    height: 50px;
    border-radius: 6px;
    max-width: 550px;
  }
}

@media screen and (max-width: 550px) {
  .caro-item {
    min-height: 220px;
    background-position: 75%;
  }
  .carou-text {
    max-width: 370px;
    height: 220px;
    padding-left: 15px;
  }
  .carou-text h2 {
    font-size: 22px;
  }
  .about-ul-out {
    flex-direction: column;
  }
  .about-ul-out ul {
    padding-left: 22px;
  }
  .youg-out img {
    max-width: 250px;
    position: relative;
  }
  .youg-out {
    text-align: center;
    margin-top: 35px;
  }
  .youg-out::after {
    top: -20px;
    right: 18px;
    z-index: -1;
    width: 233px;
    height: 230px;
  }
  .youg-out img {
    width: 100%;
    height: auto;
    object-fit: contain;
    z-index: 9;
    max-width: 250px;
    position: relative;
    border-radius: 15px;
  }
  .callForShop {
    height: 140px;
    padding: 15px;
    margin-top: 20px;
  }
  .about-out.text-center.whatWeDo-head p {
    max-width: 360px;
    line-height: 27px;
    font-size: 17px !important;
  }
  .comn-tag {
    height: 210px;
  }
  .callForShop h1 {
    font-size: 25px;
  }
  .btn.btn-shop {
    font-size: 13px;
    line-height: 25px;
  }
  .offer-content p {
    font-size: 18px !important;
  }
  .offer-content h2 {
    font-size: 30px;
  }
  .offer-dis-inner {
    height: 200px;
  }
  .offer-dis-inner {
    min-height: 210px;
  }
  .eml-inp input {
    height: 45px;
  }
  .eml-inp input::placeholder {
    font-size: 12px;
  }
  .slider-outer .owl-dots {
    margin-top: 10px;
  }
  .slider-outer .owl-dots button.owl-dot {
    width: 10px;
    height: 10px;
  }
  section.about-mms-sect {
    margin-top: 35px;
  }
  .about-out h3 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .about-out.text-center.whatWeDo-head h3::before {
    left: 140px;
  }
}

.sec-top-mar {
  margin-top: 200px !important;
}

/* landing page  reposive css */

.popular-test-owl .owl-nav.disabled,
.popular-test-owl .owl-nav.disabled {
  display: none !important;
}

.diagnostics-outer img {
  height: 70px;
  margin-bottom: 10px;
}

.popular-tests-tab ul {
  max-width: 100%;
  margin-bottom: 0 !important;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  width: 100%;
}

.popular-tests-tab ul li {
  margin-right: 35px;
}

.wapper-product .details {
  padding: 15px 6px 0px;
}

.btn.btn-contact {
  border-radius: 30px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 0 3px #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
}

.contact1st {
  position: absolute;
  bottom: 6%;
  width: 100%;
  left: 0;
}

.contact2nd {
  position: absolute;
  bottom: 6%;
  width: 100%;
  left: 7%;
}

.contact3rd {
  position: absolute;
  bottom: 6%;
  width: 100%;
  left: 7%;
}

.contact1st .btn.btn-contact {
  background: #fdfeff;
  right: -80%;
  position: relative;
  top: 15px;
  color: #07241c;
}

.contact2nd .btn.btn-contact {
  background: #d1d3ea;
  color: #375b63;
  left: 2%;
  position: relative;
}

.contact3rd .btn.btn-contact {
  background: #fdfeff;
}

.three-step-banner {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eee;
}

.three-step-banner img {
  width: 100%;
}

.product-sale-drp {
  text-align: left;
}

.product-sale-drp label {
  font-size: 14px;
  font-weight: 600;
}

.fa.fa-check.close-btn-new {
  right: 80px;
}

.drop-mm ul {
  border: 1px solid #d8d8d8;
  margin-top: 10px;
  border-bottom: 0;
}

.drop-mm li {
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid #d8d8d8;
}

.custom-serach-drp-cmp {
  background: #fff;
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 54px;
  padding: 15px 10px;
  box-shadow: 10px 24px 37px -17px #c2c2c2;
  border: 1px solid #f2eeee;
  border-radius: 5px;
}

.custom-serach-drp-cmp ul {
  list-style: none;
  padding-left: 5px;
  max-height: 600px;
  margin-bottom: 0;
  overflow-y: auto;
}

.custom-serach-drp-cmp ul li p {
  font-size: 14px;
  color: #000;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
  cursor: pointer;
}

.custom-serach-drp-cmp ul li :hover {
  color: #000;
  font-weight: bold;
  background-color: rgb(255, 229, 180);
  cursor: pointer;
}

.invoice-box h4 {
  color: #182d48;
  font-size: 14px;
  font-weight: 600;
  margin-top: 7.5px;
}

.invoice-box p {
  font-size: 18px !important;
  color: #9c9c9c;
  margin-bottom: 7.5px;
}

.invoice-box {
  padding: 35px 50px;
  background: #f4ffff;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.invoice-box h2 {
  font-size: 24px;
  color: #182d48;
  font-weight: 600;
  margin-bottom: 15px;
}

.inv-product-row {
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.invoice-box .inv-product-row:last-child {
  border-bottom: unset;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.inv-add-box {
  background: #ffff;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.inv-add-box h6 {
  font-size: 14px;
  margin-bottom: 15px;
  color: #000;
  line-height: 130%;
  float: left;
  text-align: left;
}

.inv-add-box h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #182d48;
}

.mt-breadcrumb {
  background: #f4ffff;
  padding: 100px 0px;
  text-align: center;
}

.mt-breadcrumb h1 {
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
  color: #182d48;
}

.mt-breadcrumb p {
  font-size: 14px;
  color: #9c9c9c;
  padding-top: 10px;
  line-height: 24px;
}

.blog-tabs {
  width: 100%;
  float: left;
  padding: 100px 0px;
}
.col-lg-10.padding-right-blog {
  padding-right: 150px !important;
}

.blog-tabs .nav-item .nav-link {
  font-size: 14px;
  color: #b2b2b2;
  padding-right: 45px;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 0px;
}

.blog-tabs .nav-item .nav-link.active {
  color: #182d48;
  background: transparent;
}

.blog-tabs ul {
  border-bottom: 2px solid #eee;
  padding-bottom: 15px;
}

.blog-thumb-box img {
  height: 275px;
  width: 100%;
  object-fit: cover;
}

.blog-product-badge {
  display: flex;
  align-items: center;
  justify-content: start;
  background: #e3f0ff;
  padding: 10px;
  margin-top: 30px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.blog-product-badge h5 {
  font-size: 12px;
  margin-bottom: 0px;
  padding-right: 25px;
  color: #182d48;
  text-transform: uppercase;
  font-weight: 600;
}

.blog-product-badge span {
  font-size: 12px;
  font-weight: 500;
  color: #182d48;
  position: relative;
  margin-left: 25px;
}

.blog-product-badge span::before {
  content: "";
  background: #182d48;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  position: absolute;
  left: -13px;
  top: 6.5px;
}

.blog-thumb-box {
  margin-top: 0px;
  margin-bottom: 50px;
}

.blog-thumb-box h4 {
  color: #182d48;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
  width: 377px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.blog-thumb-box p {
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 500;
  display: none;
}

.blog-thumb-box button {
  background: #69cd90;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  padding: 8px 24px;
}

.blog-details-breadcrumb h3 {
  color: #182d48;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 25px;
}

.blog-details-breadcrumb {
  padding: 100px 0px;
}

.blog-details-breadcrumb p {
  font-size: 14px !important;
  color: #9c9c9c;
}

.blog-details-breadcrumb p b {
  padding-right: 10px;
}

.social-icons-blog ul {
  list-style: none;
  background: #fff;
  padding: 15px;
  margin-bottom: 0px;
}

.social-icons-blog img {
  height: 30px;
  margin-bottom: 15px;
}

.blog-details-content h3 {
  color: #182d48;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.blog-details-content p {
  font-size: 14px !important;
  color: #9c9c9c;
  word-break: break-word !important;
  text-align: left;
}

.related-articles h3 {
  color: #182d48;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.related-articles {
  padding-top: 50px;
}

.blog-thumb-box p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
strong {
  word-wrap: break-word;
  word-break: break-all;
}

.tab-pane .blog-thumb-box p h3,
.blog-thumb-box p h1,
.blog-thumb-box p h2,
.blog-thumb-box p h4,
.blog-thumb-box p h5,
.blog-thumb-box p h6,
.blog-thumb-box p span,
.blog-thumb-box p img {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 30px;
}

@media screen and (min-device-width: 250px) and (max-device-width: 550px) {
  .custom-serach-drp-cmp {
    width: 350px !important;
    right: -40px !important;
    top: 66px;
  }
  .loc-search {
    display: block !important;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 0px !important;
  }
  .advance-search a {
    margin-top: 10px !important;
    display: block;
    font-size: 14px;
    color: #182d48;
    font-weight: 600;
    margin-left: 5px !important;
  }
  .loc-city-name {
    height: 35px !important;
    margin-top: 7px !important;
  }
  .top-text h6 {
    padding: 0px 0px !important;
  }
  .om-stat-box,
  .bl-stat-box,
  .oh-stat-box {
    margin-bottom: 30px;
  }
  .obo-wrapper {
    margin-top: 50px !important;
  }
}

/* new homepage css */

.loc-search {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 0px;
}

.loc-city-name {
  border: 1px solid #dfdfdf;
  display: flex;
  justify-content: center;
  width: 100px;
  padding-left: 0px;
  border-radius: 8px 0px 0px 8px !important;
  height: 40px;
  font-size: 12px;
  /* box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.2); */
  align-items: center;
  font-weight: 500;
  margin-right: 0px;
  margin-top: -10px;
  z-index: 99;
}

.loc-city-name img {
  height: 15px;
  object-fit: contain;
  margin-right: 5px;
}

.advance-search a {
  margin-left: 15px;
  margin-top: -10px;
  display: block;
  font-size: 14px;
  color: #182d48;
  font-weight: 600;
}

.om-stat-box {
  background: url("/assets/img/green-om-bg.png") no-repeat;
  background-size: cover;
  border-radius: 15px;
  padding: 50px;
  height: 200px;
  display: flex;
  align-items: center;
}

.bl-stat-box {
  background: url("/assets/img/blue-bl-bg.png") no-repeat;
  background-size: cover;
  border-radius: 15px;
  padding: 50px;
  height: 200px;
  display: flex;
  align-items: center;
}

.oh-stat-box {
  background: url("/assets/img/purple-oh-bg.png") no-repeat;
  background-size: cover;
  border-radius: 15px;
  padding: 50px;
  height: 200px;
  display: flex;
  align-items: center;
}

.obo-wrapper h3 {
  font-size: 24px;
  font-weight: 800;
  color: #182d48;
  margin-bottom: 5px;
  word-break: break-word;
}

.obo-wrapper button {
  background: #fff;
  margin-top: 30px;
  font-weight: 600;
  font-size: 13px;
  padding: 5px 20px;
  color: #182d48 !important;
}

.obo-wrapper {
  margin-top: 100px;
}

.hm-blog-wrp {
  padding-bottom: 50px;
}

.view-all-link a {
  font-size: 16px;
  font-weight: 600;
  color: #172c47;
  text-align: right;
  display: block;
  padding-top: 15px;
}

.lab-test-banner {
  background: url("/assets/img/path-banner.png") no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 100px;
  background-position: 50%;
  margin-top: 200px;
}

.lab-test-banner h1 {
  color: #182d48;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 700;
  word-break: break-word;
  line-height: 125%;
}

.dfo-wrapper {
  padding-top: 100px;
}

.doc-ver-reports {
  background: url("/assets/img/pink-bg.png") no-repeat;
  background-size: cover;
  text-align: center;
  height: 175px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.home-coll-reports {
  background: url("/assets/img/blue-bg.png") no-repeat;
  background-size: cover;
  text-align: center;
  height: 175px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.online-over-reports {
  background: url("/assets/img/green-bg.png") no-repeat;
  background-size: cover;
  text-align: center;
  height: 175px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.dfo-wrapper h3 {
  font-size: 18px;
  font-weight: 600;
  padding-left: 165px;
  padding-right: 25px;
  text-align: left;
  word-break: break-word;
  line-height: 125%;
  margin-bottom: 0px;
}

.whatsapp-banner-content {
  background: url("/assets/img/whatsapp-bg.png") no-repeat;
  background-size: 100%;
  padding: 100px 0px;
  margin-bottom: 100px;
  background-position: center;
}

.wb-banner-content h1 {
  font-size: 40px;
  color: #182d48;
  font-weight: 700;
  margin-bottom: 5px;
}

.wb-banner-content h6 {
  font-size: 20px;
  color: #182d48;
  font-weight: 400;
  letter-spacing: 1px;
}

.wb-banner-content p img {
  height: 25px;
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.wb-banner-content p {
  font-size: 18px !important;
  margin-bottom: 0px;
  color: #182d48;
  font-weight: 700;
  padding-top: 5px;
}

.wb-btn-wrp {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wb-btn-wrp .btn {
  background: #69cd90;
  color: #fff;
  padding: 8px 36px;
  font-weight: 500;
  font-size: 14px;
}

.second-nav-wrp {
  padding-bottom: 25px;
}

.second-nav-wrp .nav-link {
  color: #182d48 !important;
  text-transform: capitalize;
  font-size: 14px;
  padding-left: 15px !important;
}

.second-nav-wrp .nav-link::after {
  position: absolute;
  right: 15px;
  top: 10px;
}

.second-nav-wrp .dropdown-menu {
  position: static;
  float: none;
  border: unset;
  left: 0 !important;
  right: auto;
  top: 15px;
  padding-bottom: 15px;
}

.second-nav-wrp .dropdown-item {
  border-bottom: unset;
  padding-bottom: 5px;
  font-size: 14px;
  color: #333 !important;
}

.second-nav-wrp .navbar-toggler-icon {
  display: block !important;
  width: 30px;
}

.second-nav-wrp .navbar-toggler.collapsed {
  background: transparent;
  position: absolute;
  top: -135px;
  left: 0;
  transition: all ease 0.9s;
  padding: 5px 0px;
  width: 30px;
  outline: unset !important;
}

.second-nav-wrp .navbar-toggler {
  position: absolute;
  top: -130px;
  transition: all ease 0.9s;
  left: 0;
}

.second-nav-wrp .navbar-nav .dropdown-menu {
  position: static;
  float: none;
  margin: 15px !important;
  margin-bottom: 25px !important;
  width: 250px;
}

.freq-book-wrapper {
  padding-top: 75px;
}

.freq-book-content ul {
  padding-left: 0px;
  list-style: none;
  padding-top: 25px;
}

.freq-greenbox {
  background: #8bdca9;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-right: 20px;
}

.freq-bluebox {
  background: #8bbedc;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-right: 20px;
}

.freq-yellowbox {
  background: #dccc8b;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-right: 20px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}

.freq-book-content ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.freq-book-wrapper .owl-nav {
  top: 120px !important;
}

.second-nav-wrp nav {
  padding: 0 !important;
}

.second-nav-wrp .nav-link:first-child {
  padding-left: 5px !important;
}

.second-nav-wrp .navbar-toggler.collapsed {
  display: none;
}
.navbar-brand {
  z-index: 1;
}
iframe {
  display: none;
}

.wish-cart-ul {
  width: 150px;
  margin-left: auto;
  margin-right: -15px;
}

.wish-cart-ul a {
  color: #000 !important;
}

body.navbar_change {
  overflow: hidden !important;
}

body.navbar_change .home-top-banner {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

body.navbar_change .loc-search,
body.navbar_change .wish-cart-ul {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.ls-flex {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin-bottom: 25px;
}

.ls-flex a {
  padding: 0px !important;
  margin: 0px !important;
}

.ls-flex a.pl-0 {
  padding-left: 0px !important;
}
.order-btn {
  background-color: #182d48 !important;
  color: #fff !important;
}
.img-product i {
  position: absolute;
  top: 15px;
  right: 15px;
}

.img-product {
  position: relative;
}

.navbar_change .navbar-toggler .navbar-toggler-icon {
  background-image: url("/assets/img/close.png") !important;
}

.people-owl .wapper-about {
  border: 1px solid #dfdfdf;
  padding: 25px;
  height: 100%;
  border-radius: 8px;
  min-height: 265px;
}

.react-datepicker__input-container input,
.react-datepicker__input-container input::placeholder {
  text-align: center !important;
}

.react-datepicker__input-container input,
.react-datepicker__input-container input::placeholder {
  font-size: 12px !important;
}

.dropdown-toggle::after {
  border-top: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-left: 0.4em solid transparent !important;
}

@media screen and (min-device-width: 250px) and (max-device-width: 550px) {
  .navbar-dark .navbar-toggler {
    border: none;
    outline: none;
  }
}
#dropdown-split-basic {
  border: none;
}

.remove-move .pr-0 {
  position: relative;
}
.remove-move .fa-heart-o {
  float: right;
  position: absolute;
  top: 1px !important;
  left: -19px !important;
  /* font-size: 20px; */
  color: #000;
  z-index: 3;
}

.add-product-list .price {
  position: absolute !important;
  right: 73px !important;
  top: 6px !important;
}

.offers-home h2 {
  margin-top: 20px !important;
}

.offers-home-owl {
  margin-top: 20px !important;
}

.order-button {
  padding-top: 15px !important;
  padding-bottom: 20px;
}
.product-search {
  border: 1px solid #cccc;
  padding: 10px;
  width: 20%;
}
.product-search:focus {
  border: 1px solid #000 !important;

  outline: none;
}
.product-search-btn {
  background-color: #182d48;
  border: 1px solid #cccc;
  padding: 10px;
  color: #fff;
  font-weight: 700;
}
.product-list td {
  padding: 20px !important;
  border-top: none !important;
}

.input_outside {
  width: 100% !important;
  overflow-x: hidden !important;
}

.react-dropdown-select-clear {
  position: absolute;
  right: 15px;
}

.react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 0;
  top: 5px;
}

.input_outside input {
  padding-right: 25px;
  min-width: 200px;
}
